.product__options {
    @include screen-480 {
        flex: 0 0 auto;
        max-width: 100%;
    }

    .available-options {
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }
}

.colour-options-list {
    flex: 0 0 100%;
    max-width: 100%;
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    &__item {
        margin-left: 1px;
        margin-right: 1px;

        &.with-label {
            flex: 0 0 60px;
            width: 60px;
            margin-left: 0;
            margin-right: -1px;
            padding: 3px;
            border: 1px solid $color-grey-01;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: $fs-small;
            line-height: 1;

            .colour-options__dot {
                margin-bottom: 0.33em;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &[style="background: #ffffff; border-color: #ffffff;"] {
            border-color: $color-grey-02 !important;
        }
    }
}

.colour-options__dot {
    display: block;
    border: 1px solid $color-grey-04;
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    border-radius: 100%;

    &[style="background: ; border-color: ;"] {
        display: none;
    }

    @include screen-1200 {
        flex: 0 0 15px;
        width: 15px;
        height: 15px;
    }
}

.size-options-list {
    flex: 0 0 auto;
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-bottom: 0;

    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: $fs-small;
        color: $color-grey-04;
        font-weight: $fw-bold;
        margin-left: 3px;
        margin-right: 3px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.colour-options-list--labels {
    margin-top: -1px;
}

.colour-options-list--labels + .size-options-list {
    margin-top: -1px;

    li {
        flex: 0 0 60px;
        width: 60px;
        padding: 3px;
        margin: 0;
        margin-right: -1px;
        border: 1px solid $color-grey-01;
    }
}
