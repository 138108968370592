@mixin product-image-flags() {
    position: absolute;
    left: 9px;
    top: 9px;
    z-index: 1;
}

@mixin product-flag($small: false) {
    .flag {
        line-height: normal;
        padding: 3px 6px;
        padding-left: calc(6px + 0.25em);
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 0.25em;
        font-size: $fs-small;

        @if $small {
            padding: 0 5px;
            padding-left: calc(5px + 0.25em);
        }
    }

    .sale {
        background-color: $color-04;
        color: $color-white;
    }
}

