
/* ---- Progress steps ---- */
.steps {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $color-grey-01;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
    clear: both;
}

.steps h3 {
    font-size: $fs-body*0.8;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.25em;

    @media screen and (min-width: 87.5em) {/* 1400px */
        font-size: $fs-body;
    }
}

.steps__list {
    position: relative;
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    text-align: center;
}

.steps__item {
    position: relative;
    display: inline-block;
    text-align: center;
    z-index: 2;

    box-sizing: border-box;
    flex: 0 0 50%;

    margin-bottom: 10px;
}

.steps__item .title {
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
    padding-top: 4px;

    @media only screen and (min-width: 62em) { /* 992px */
        .steps__item .title {
            font-size: 1.1rem;
        }
    }
}

.steps__item a {
    color: inherit;
    text-decoration: none;
    display: block;
}

.steps__item .dot {
    display: block;
    width: 32px;
    height: 32px;
    border: 2px solid $color-white;
    background-color: $color-white;
    color: $color-grey-05;
    box-shadow: 0 2px 18px rgba($color-black, 0.2);
    font-size: 1.6rem;
    border-radius: 100%;
    line-height: 1;
    margin-left: auto;
    margin-right: auto;
    padding: 7px 0 5px;
    z-index: 1;
}

.steps__item.populated .dot {
    border-color: $color-grey-06;
    background-color: $color-grey-06;
    color: $color-white;
    box-shadow: 0 0 0 rgba($color-black, 0);
}

.steps__item.active .dot {
    border-color: $color-00;
    background-color: $color-00;
    color: $color-white;
    opacity: 1;
}

.deactive {
    opacity: 0.33;
}

/* BP MAX Smaller */
@media screen and (max-width: 42.4375em) { /* 679px */
    .steps__item {
        height: 55px;
    }

    .steps__item:nth-child(2n+1) .dot:before {
        content: '\00a0';
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        border-left: 2px solid $color-grey-06;
        border-top: 2px solid $color-grey-06;
        border-bottom: 2px solid $color-grey-06;
        height: 25px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        z-index: -1;
    }

    .steps__item:nth-child(2n+2) .dot:before {
        content: '\00a0';
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
        border-right: 2px solid $color-grey-06;
        border-top: 2px solid $color-grey-06;
        border-bottom: 2px solid $color-grey-06;
        height: 44px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        z-index: -1;
    }

    .steps__item:first-child .dot:before {
        border-top: none;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .steps__item:first-child .dot:after {
        content: '\00a0';
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        height: 8px;
        width: 8px;
        background-color: $color-grey-06;
        border-radius: 4px;
    }

    .steps__item:last-child .dot:before {
        border-right: none;
        border-top: 2px solid $color-grey-06;
        border-bottom: none;
        border-top-right-radius: 0;
    }

    .steps__item:nth-child(odd):last-child .dot:before {
        border-bottom: 2px solid $color-grey-06;
    }

    .steps__item:last-child .dot:after {
        content: '\00a0';
        position: absolute;
        top: calc(50% - 15px);
        left: calc(100% - 8px);
        height: 8px;
        width: 8px;
        background-color: $color-grey-06;
        border-radius: 4px;
    }
}

/* BP Smaller */
@media screen and (min-width: 42.5em) { /* 680px */
    .steps {
        overflow: hidden;
    }

    .steps__list {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;

        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .steps__item {
        position: relative;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 88px;

        min-width: 88px;
    }

    .steps__item:before,
    .steps__item:after {
        content: '\00a0';
        position: absolute;
        top: 17px;
        height: 2px;
        background-color: $color-00;
        width: calc(23vw - 110px);
        z-index: 0;
    }

    .steps__item:before {
        right: 100%;
        left: auto;
    }

    .steps__item:after{
        left: 100%;
        right: auto;
    }

    .steps__item:first-child:before,
    .steps__item:last-child:after {
        content: none;
    }

    .steps__item .dot {
        position: relative;
        width: 40px;
        height: 40px;
        padding-top: 12px;
        padding-bottom: 9px;
    }
}

@media only screen and (min-width: 48em) { /* 768px */
    .steps {
        padding-top: 20px;
        padding-bottom: 20px;
        min-width: 0;
        margin-left: 0;
    }
}

@media only screen and (min-width: 62em) { /* 992px */
    .steps {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}


@media screen and (min-width: 100em) {/* 1600px */
    .steps__item:before,
    .steps__item:after {
        width: 250px;
    }
}
