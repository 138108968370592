
.product-list {
    &__item {
        line-height: normal;
        margin-bottom: $fs-body;
        display: flex;
        margin-left: -5px;
        margin-right: -5px;

        &__title {
            font-weight: $fw-bold;
            margin-bottom: 0;
        }
        &__variant {
            display: flex;
            align-items: center;
            // margin-top: auto;
            margin-bottom: 0;

            .colour-options__dot {
                margin-right: 0.33em;
            }
        }
        &__column {
            padding-left: 5px;
            padding-right: 5px;

            &.image {
                flex: 0 0 60px;
                max-width: 60px;
                display: none;

                @include screen-480 {
                    display: block;
                }
            }
            &.lhs {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
            &.rhs {
                flex: 0 0 auto;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }
        &__img {
            display: block;
            background-color: $color-grey-01;

            @supports( aspect-ratio: 9/16 ) {
                aspect-ratio: 9/16;

                @include screen-992 {
                    aspect-ratio: 103/120;
                }
            }

            img {
                margin: auto;

                @supports( aspect-ratio: 9/16 ) {
                    object-fit: contain;
                    object-position: center;
                    height: 100%;
                }
            }
        }
        &__quantity {
            margin-bottom: 0;

            .preceding {
                font-family: $accent-font;
                text-transform: uppercase;
            }
        }
        &__cost {
            margin-bottom: 0;

            .price {
                display: block;
            }

            .price--original {
                font-size: $fs-small;

                .original {
                    text-decoration: line-through;
                }
            }

            .price--current {
                font-weight: $fw-bold;
                color: $color-primary;
            }
        }
        &__flags {
            list-style-type: none;
            margin: 0;
            padding: 0;
            @include product-flag(true);
        }
    }
}

