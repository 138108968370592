
.product__picture-link {
    display: block;
}

.product__picture {
    display: block;
    position: relative;
    background-color: $color-grey-01;
    margin-bottom: 0;
    transition:
        opacity 300ms ease,
        mix-blend-mode 300ms ease;

    @supports(aspect-ratio: 3/4) {
        aspect-ratio: 3/4;
    }

    &__img {
        @supports(mix-blend-mode: multiply) {
            mix-blend-mode: multiply;
        }

        @supports(object-fit: contain) {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        &.no-image {
            @supports(filter: grayscale(1)) {
                filter: grayscale(1);
                opacity: 0.8;
            }
        }
    }

    &.placeholder {
        align-items: center;
    }
}

.product__image-slider {
    position: relative;

    &__track {
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }

    .slider__arrows {
        z-index: 10;

        @include screen-480 {
            width: 36px;
            height: 36px;
            top: calc(50% - 18px);
        }

        &.previous {
            right: calc(100% - 32px);
        }

        &.next {
            left: calc(100% - 32px);
        }
    }

    .slider__dots {
        display: none;
    }
}


.product__image-slider .product__picture-link {
	width: 100%;
	flex-shrink: 0;
	scroll-snap-align: start;
}

.product__image-slider .product__picture {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        object-fit: contain;
    }
}


.product__image-flag {
    &.flag--colour {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        padding: .25em .4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;

        .badge {
            font-size: inherit;
        }

        .product__image-flag__colour {
            margin-bottom: 0.25em;
        }

        .product__image-flag__subtitle {
            display: block;
            margin-bottom: 0;
            font-weight: normal;
        }

        .size-options-list {
            display: block;
            margin: 0.15em auto 0;

            li {
                color: inherit;
                display: inline-block;
                width: auto;
                height: auto;
                font-size: inherit;
            }
        }
    }

    &.flag--no-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        background-color: rgba($color-black, 0.75);
        color: $color-white;
        font-size: 75%;
        margin-top: $fs-body*0.75;
        padding: 8px 40px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1.5px;
    }
}
