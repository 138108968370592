@charset "UTF-8";
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Basic system font stacks
*/
/* Poppins Weights: Regular: 400, Bold: 700 */
/* Lato Weights: Light: 300, Regular: 400 */
/*
Weights
*/
/*
Sizes
*/
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in down */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.highlight-pulse {
  animation: highlightPulse 700ms ease;
  animation-fill-mode: forwards;
}

.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
/* Red Warning Button */
/* Light Grey */
/* Mid Grey */
/* Green */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* ---- Craft Commerce ---- */
/* globals */
.floating-cart__anchor {
  position: relative;
  display: flex;
}

.floating-cart {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  background-color: #f9fbfb;
  border: 1px solid #E9EFF2;
  padding: 0;
  font-size: 1.5rem;
  border-radius: 4px;
  margin-top: 7px;
  text-align: left;
  color: #6A6D73;
  z-index: 100;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
  width: 280px;
  z-index: 60;
  text-align: left;
}
.floating-cart[aria-expanded=false] {
  display: none;
}
.floating-cart:before, .floating-cart:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #f9fbfb transparent;
  z-index: 1;
}
.floating-cart:after {
  left: 13px;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #E9EFF2 transparent;
  z-index: 0;
}
.floating-cart:before {
  right: 14px;
  left: auto;
}
.floating-cart:after {
  right: 13px;
  left: auto;
}
.floating-cart__trigger {
  display: flex;
  align-items: flex-end;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.floating-cart__trigger__icon {
  display: block;
}
.floating-cart__trigger__label {
  display: block;
  margin-left: 2px;
}
.floating-cart__trigger__label span {
  display: block;
  background-color: #1B244A;
  color: #ffffff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-weight: 700;
  text-align: center;
}
.floating-cart__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.floating-cart__title {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2.8rem;
  line-height: 1;
}
.floating-cart__exit {
  position: relative;
  height: 32px;
}
.floating-cart__exit button {
  height: 32px;
  width: 32px;
  display: block;
  cursor: pointer;
  background-color: transparent;
  background-image: url(../assets/icon-system/icon_form_cross.svg);
  background-size: contain;
  background-repeat: none;
  background-position: center center;
  background-repeat: no-repeat;
  text-decoration: none;
  border: none;
  outline: none;
  transition: all 250ms ease-in-out;
  padding: 0;
}
.floating-cart__items-list > *:first-child {
  margin-top: 0.5em;
}
.floating-cart__items-list > *:last-child {
  margin-bottom: 0.5em;
}
.floating-cart__item {
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.33;
}
.floating-cart__item__description {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 48em) {
  .floating-cart__item__description {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .floating-cart__item__description {
    letter-spacing: 0.25em;
  }
}
.floating-cart__item__description a {
  text-decoration: none;
}
.floating-cart__item__notes {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
  margin-bottom: 0;
}
.floating-cart__item__notes .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 0 5px;
  padding-left: calc(5px + 0.25em);
}
.floating-cart__item__notes .sale {
  background-color: #364896;
  color: #ffffff;
}
.floating-cart__item__notes .quantity:after {
  content: " ";
}
.floating-cart__item__price {
  display: flex;
  align-items: baseline;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
  margin-bottom: 0;
}
.floating-cart__item__price .price {
  margin-bottom: 0;
}
.floating-cart__item__price .price--original {
  font-size: 75%;
  margin-right: 0.5em;
  text-decoration: line-through;
}
.floating-cart__item__price .price--current {
  font-weight: 700;
}
.floating-cart__item__price .sale-unit-price {
  font-size: 75%;
}
.floating-cart__adjustments {
  border-top: 1px solid #E9EFF2;
  border-bottom: 1px solid #E9EFF2;
  padding: 10px;
}
.floating-cart__adjustments__title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  color: #1D1E26;
  margin-bottom: 0.25em;
  font-size: 1.5rem;
}
@media screen and (min-width: 48em) {
  .floating-cart__adjustments__title {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .floating-cart__adjustments__title {
    letter-spacing: 0.25em;
  }
}
.floating-cart__adjustments__item {
  display: block;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.33;
  margin-bottom: 0.5em;
}
.floating-cart__adjustments__item__description {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: calc(100% - 40px);
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.5;
}
.floating-cart__subtotal {
  border-top: 3px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 0;
}
.floating-cart__subtotal__title {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}
@media screen and (min-width: 48em) {
  .floating-cart__subtotal__title {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .floating-cart__subtotal__title {
    letter-spacing: 0.25em;
  }
}
.floating-cart__subtotal__quantity {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
  font-weight: 700;
}
.floating-cart__ctas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}
.floating-cart__ctas .button,
.floating-cart__ctas .text-link {
  letter-spacing: 0.1em;
}
.floating-cart__empty-note {
  margin-bottom: 0;
}

/* cart */
.cart-notices {
  padding: 15px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-bottom: 3px solid #6A6D73;
  background-color: #F0F4F6;
  margin: 1em 0;
}
.cart-notices > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .cart-notices {
    padding: 21px;
  }
}
.cart-notices .cart-notices__title {
  margin-bottom: 0;
}
.cart-notices .cart-notices__list {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  margin-bottom: 0.5em;
  border-left: 3px solid #1B244A;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.cart-notices .cart-notices__list.invalid-coupon-removed {
  border-left-color: #C32C2A;
}
.cart-notices .cart-notices__list.line-item-removed {
  border-left-color: #C32C2A;
}
.cart-notices .cart-notices__list.shipping-method-changed {
  border-left-color: #C32C2A;
}
.cart-notices .cart-notices__item {
  position: relative;
  display: block;
  padding: 0.75rem 0;
}
.cart-notices .cart-notices__item.warning {
  color: #A72D00;
}
.cart-notices .cart-notices__item.error {
  color: #9E0E0B;
}
.cart-notices .cart-notices__item.confirm {
  color: #2D4A13;
}

/* checkout */
/*
// Address Select
.address-list__title {
    @include tagline;
}

.address-list {
    @include box;
    @include box-lined;

    .button {
        @include button-00;
    }
}
*/
@supports (display: grid) {
  .address-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
  }
  .address-grid--large {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

.address-option {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
}
@supports (display: grid) {
  .address-option {
    margin-bottom: 0;
  }
}
.address-option__label {
  border: 1px solid #F0F4F6;
  padding: 16px;
}
@media screen and (min-width: 87.5em) {
  .address-option__label {
    padding: 24px;
  }
}
.address-option__label .label-body {
  line-height: 2;
  flex: 1 1 calc(100% - 24px);
  padding-left: 8px;
}
.address-option__label .address-data {
  margin-top: 8px;
}
.address-option__radio-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.address-option__radio-btn input[type=radio] {
  margin-top: 0.25em;
}

.address-option__label:focus-within {
  border-color: transparent;
  outline: 2px solid #1B244A;
}

.order-review .address-select {
  padding: 15px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #6A6D73;
  line-height: normal;
  margin-bottom: 0;
}
.order-review .address-select > *:last-child {
  margin-bottom: 0;
}

.address-edit {
  margin-bottom: 0;
  margin-top: auto;
  flex: 0 0 100%;
}

.address-select__buttons {
  margin-bottom: 0;
  margin-top: auto;
}

.customer__addresses .address-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.address-data .emphasis {
  color: #1D1E26;
}
.address-data .name,
.address-data .phone,
.address-data .address1,
.address-data .address2,
.address-data .business-name {
  display: block;
}
.address-data .city:after,
.address-data .state-text:after {
  content: ",";
}

.address-data + .address-data {
  margin-top: 8px;
}

.address-fieldset.ShippingAddress {
  margin-top: 3rem;
}

.order-address {
  margin-bottom: 0;
}

.address-set-as {
  border-left: 3px solid #60962F;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-left: 12px;
  margin-bottom: calc(1.5rem + 0.25em);
}
@media screen and (min-width: 48em) {
  .address-set-as {
    padding-left: 15px;
    margin-left: -16px;
  }
}
.address-set-as__title {
  font-size: 1.2rem;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}
.address-set-as__item {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.address-set-as__item i {
  margin-right: 4px;
}
.address-set-as > *:last-child {
  margin-bottom: 0;
}

.gift-address {
  padding-bottom: 6px;
}
.gift-address .title-style-03 {
  color: #571E25;
}
.gift-address .shippingAddress .box-lined {
  border-left-color: #571E25;
}

.checkout__subtitle {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
@media screen and (min-width: 87.5em) {
  .checkout__subtitle {
    /* 1400px */
    font-size: 1.65rem;
  }
}

.hidden {
  display: none;
}

.field-input .textbox.hidden {
  display: none;
}

.field-input .dropdown.hidden {
  display: none;
}

@media screen and (min-width: 48em) {
  .form__checkout-email input[type=submit],
.form__checkout-email button[type=submit] {
    flex: 0 0 33.333%;
  }
}

@media screen and (min-width: 48em) {
  .form__password-reset input[type=submit],
.form__password-reset button[type=submit] {
    flex: 0 0 33.333%;
  }
}

.order-table {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  min-width: 0;
  vertical-align: top;
  line-height: 1.5;
}
@media only screen and (max-width: 47.9375em) {
  .order-table {
    display: block;
    border: none;
  }
  .order-table tbody,
.order-table tr {
    display: block;
    border: none;
  }
  .order-table thead,
.order-table th {
    display: none;
  }
  .order-table tr {
    display: table;
    width: 100%;
  }
  .order-table tr.table--responsive__first-row {
    display: none;
  }
  .order-table td {
    display: table-row;
    padding: 0;
  }
  .order-table td[data-title]:before {
    content: attr(data-title);
    background-color: #F0F4F6;
    border-right: 1px solid #E9EFF2;
  }
  .order-table td[data-title] {
    border-left: 1px solid #E9EFF2;
    border-right: 1px solid #E9EFF2;
    border-top: 1px solid #E9EFF2;
  }
  .order-table tbody tr {
    margin-bottom: 0;
    border-bottom: none;
  }
  .order-table td[data-title]:before,
.order-table .table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
.order-table th,
.order-table thead td,
.order-table td {
  padding: 0;
  border: 0;
  background: none;
}
@media screen and (min-width: 48em) {
  .order-table th,
.order-table thead td {
    position: relative;
    background-color: #F0F4F6;
    padding: 10px 10px calc(10px + 1em);
  }
  .order-table th:before,
.order-table thead td:before {
    content: "";
    position: absolute;
    height: 1em;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }
}
.order-table .adjustments h3 {
  font-size: 1.2rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.2em;
  letter-spacing: 0.2em;
}
.order-table .adjustments .order-table__adjustments__item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.2em;
}
.order-table .adjustments .order-table__adjustments__item__label {
  text-align: left;
  font-size: 1.2rem;
  flex: 1 1 auto;
  line-height: normal;
  margin-bottom: 0.2em;
  padding-right: 1.5rem;
}
.order-table .adjustments .order-table__adjustments__item__price {
  flex: 0 0 auto;
  margin-bottom: 0;
}
.order-table .adjustments--order {
  padding-top: 1.5rem;
}
.order-table .adjustments--order-totals h3 {
  text-align: right;
  margin-right: -0.2em;
}
.order-table .adjustments--order-totals p {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .order-table .row-divider-top:first-child {
    border-top: 1px solid #ffffff;
  }
}
@media screen and (min-width: 48em) {
  .order-table .row-divider-top {
    border-top: 1px solid #ffffff;
    padding-top: 1.5rem;
  }
}

.order-table .field-element .field-label {
  color: #9c9c9c;
}
.order-table .field-element .title-value-item__title {
  color: #6A6D73;
  margin-bottom: 0.25em;
}
.order-table__header__title {
  display: table-cell;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
}
@media screen and (min-width: 62em) {
  .order-table__header__title {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 35em) {
  .order-table__header__title {
    font-size: 2.1rem;
  }
}
.order-table__body {
  vertical-align: top;
}

.order-table__line-item {
  margin-bottom: 40px;
}
@media screen and (min-width: 48em) {
  .order-table__line-item {
    background-color: #F0F4F6;
    border: none;
    padding: 0;
    margin-bottom: 0;
  }
}
.order-table__line-item__title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.65rem;
  font-weight: 400;
  color: #1B244A;
  margin-bottom: 0;
}
.order-table__line-item__options {
  font-size: 1.2rem;
}
@media screen and (min-width: 36em) {
  .order-table__line-item .right-column {
    /* 576px */
    border-left: none;
  }
}
.order-table__line-item .right-column .sales-applied .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
.order-table__line-item .right-column .sales-applied .sale {
  background-color: #364896;
  color: #ffffff;
}
.order-table__line-item .line-total {
  border-top: 2px solid #ffffff;
  padding-top: 1.5rem;
}
.order-table__line-item .field-element input,
.order-table__line-item .field-element select {
  background-color: transparent;
  font-family: inherit;
  color: inherit;
  line-height: normal;
  height: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.order-table__line-item .field-element.quantity input {
  width: 6em;
  padding-right: 0;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .order-table__line-item {
    border-left: 2px solid #1D1E26 !important;
  }
  .order-table__line-item td > * {
    padding-left: 10px !important;
  }
}
@media screen and (min-width: 48em) {
  .order-table__line-item td {
    background-color: #F0F4F6;
    padding: 10px;
  }
  .order-table__line-item .field-element input,
.order-table__line-item .field-element select,
.order-table__line-item .checkbox__label--wrap .pseudo-input {
    background-color: #ffffff;
  }
  .order-table__line-item:nth-child(3n+2) td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .order-table__line-item:nth-child(3n+2) td:last-child {
    border-top-right-radius: 12px;
  }
  .order-table__line-item:nth-child(3n) td:last-child {
    border-bottom-right-radius: 12px;
  }
}
@media screen and (min-width: 48em) {
  .order-summary .order-table__line-item:nth-child(3n+2) td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .order-summary .order-table__line-item:nth-child(3n+2) td:last-child {
    border-top-right-radius: 0;
  }
  .order-summary .order-table__line-item:nth-child(3n) td:last-child {
    border-bottom-right-radius: 0;
  }
}
@media screen and (min-width: 48em) {
  .order-table__line-item--spacer td {
    background-color: #ffffff;
  }
}
@media only screen and (max-width: 767px) {
  .order-table__line-item--spacer {
    height: 1.5em;
    margin-bottom: 1.5em !important;
    display: block !important;
    border-left: none !important;
    border-bottom: 2px solid #b8b8b8 !important;
  }
}

.order-table__all-items {
  border-top: 1px solid #F0F4F6;
  border-bottom: 1px solid #F0F4F6;
}
@media screen and (min-width: 48em) {
  .order-table__all-items {
    border-top: none;
  }
}
@media only screen and (max-width: 767px) {
  .order-table__all-items {
    border-top: 2px solid #b8b8b8 !important;
    margin-top: 1.5em;
  }
  .order-table__all-items td p {
    padding-top: 1em;
  }
}
.order-table__all-items.with-adjustments {
  margin-bottom: 1.5rem;
}
.order-table__all-items .item-sub-total {
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 48em) {
  .order-table__all-items .item-sub-total {
    padding-top: 1.5rem;
  }
}
.order-table__all-items .quantity {
  font-size: 150%;
  line-height: 1.15;
}
.order-table__all-items .adjustments {
  border-bottom: 1px solid #E9EFF2;
  margin-bottom: 1.5rem;
}

.order-table__order-overview {
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .order-table__order-overview {
    border-top: 2px solid #b8b8b8 !important;
  }
}
.order-table__order-overview .coupon-code {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .order-table__order-overview .coupon-code {
    padding-top: 1em;
  }
}
.order-table__order-overview .coupon-code__title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 62em) {
  .order-table__order-overview .coupon-code__title {
    font-size: 2.1rem;
  }
}
.order-table__order-overview .adjustments {
  display: block;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #F0F4F6;
}
@media screen and (min-width: 48em) {
  .order-table__order-overview .adjustments {
    display: table-cell;
  }
}
.order-table__order-overview .error-message {
  font-size: 1.875rem;
}

.order-table__total {
  border-top: 1px solid #F0F4F6;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media only screen and (max-width: 767px) {
  .order-table__total {
    display: block;
  }
}
.order-table__total .total-price {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media only screen and (max-width: 767px) {
  .order-table__total .total-price {
    display: block;
  }
}
.order-table__total .total-price .quantity {
  font-size: 200%;
  line-height: 1;
}

.order-table .right-column {
  text-align: right;
  vertical-align: bottom;
}
.order-table .right-column p {
  margin-bottom: 0;
}
.order-table .right-column .line-total {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 48em) {
  .order-table .right-column .line-total {
    margin-bottom: 3rem;
  }
}

.additional-info--mobile {
  display: block;
}
@media screen and (min-width: 36em) {
  .additional-info--mobile {
    /* 576px */
    display: none;
  }
}

.additional-info--desktop {
  display: none;
}
@media screen and (min-width: 36em) {
  .additional-info--desktop {
    /* 576px */
    display: block;
  }
}

.site-messages__item .order-table__line-item__title {
  color: inherit;
}

/* ---- Order Summary ---- */
@media screen and (min-width: 48em) {
  .order-summary .right-column + .order-table__line-item td {
    padding-top: 1.5rem;
  }
}
.order-summary .order-table__line-item .line-total {
  border-top: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2b2b2b;
}
.order-summary .order-table__line-item .line-item__cost-summary {
  font-size: 1.2rem;
}
.order-summary .order-table__line-item .line-item__cost-summary .flag {
  padding: 0;
  background-color: transparent;
  color: inherit;
  letter-spacing: normal;
  text-transform: none;
}
.order-summary .order-table__line-item .line-item__cost-summary .flag.sale {
  color: #364896;
}
.order-summary .order-table__order-overview .adjustments {
  font-size: 1.2rem;
}
.order-summary .additional-info--mobile {
  display: block;
}
@media screen and (min-width: 62em) {
  .order-summary .additional-info--mobile {
    /* 992px */
    display: none;
  }
}
.order-summary .additional-info--desktop {
  display: none;
}
@media screen and (min-width: 62em) {
  .order-summary .additional-info--desktop {
    /* 992px */
    display: block;
  }
}

.order-table__upload-image-message {
  display: inline-block;
  max-width: fit-content;
}

.order-summary__summary .title-value-item__title {
  color: #1B244A;
}
.order-summary__note {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.order-review {
  padding-top: 45px;
}
@media screen and (min-width: 48em) {
  .order-review {
    padding-top: 63px;
  }
}
@media screen and (min-width: 62em) {
  .order-review {
    padding-top: 0;
  }
}
.order-review__cart-total {
  align-items: flex-end;
  margin-top: 16px;
  margin-bottom: 24px;
}
.order-review__cart-total .total-price {
  font-size: 200%;
  line-height: 1;
}
.order-review__shipping {
  padding: 15px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #1B244A;
}
.order-review__shipping > *:last-child {
  margin-bottom: 0;
}
.order-review__shipping__status {
  margin-bottom: 0.5em;
}
.order-review__title {
  margin-bottom: 0.75rem;
}

.payment-form .stripe-payment-intents-form,
.stripe-payment-intents-form {
  width: 100%;
}

input.text,
.card-data {
  width: 100%;
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  padding: 10px 16px 10px;
  -webkit-appearance: none;
  line-height: 1.4;
  border: 2px solid #E9EFF2;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
}

.payment-form .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.payment-form .grid > * {
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.payment-form input[type=text],
.payment-form input[type=tel] {
  margin-bottom: 1.5rem;
  border: 2px solid #E9EFF2;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
}
.payment-form legend {
  font-weight: 700;
}
.payment-form .card-data legend {
  background: white;
  padding: 2px 8px;
}

#paymentForm input[type=text],
#paymentForm input[type=tel] {
  padding: 8px 16px;
  border: 1px solid #E9EFF2;
  border-radius: 0.25rem;
  line-height: 1.25;
  margin-bottom: 0.25rem;
}

#paymentForm fieldset {
  margin-bottom: 0.75rem;
}

/* customer */
.customer__orders {
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 47.9375em) {
  .customer__orders {
    display: block;
    border: none;
  }
  .customer__orders tbody,
.customer__orders tr {
    display: block;
    border: none;
  }
  .customer__orders thead,
.customer__orders th {
    display: none;
  }
  .customer__orders tr {
    display: table;
    width: 100%;
  }
  .customer__orders tr.table--responsive__first-row {
    display: none;
  }
  .customer__orders td {
    display: table-row;
    padding: 0;
  }
  .customer__orders td[data-title]:before {
    content: attr(data-title);
    background-color: #F0F4F6;
    border-right: 1px solid #E9EFF2;
  }
  .customer__orders td[data-title] {
    border-left: 1px solid #E9EFF2;
    border-right: 1px solid #E9EFF2;
    border-top: 1px solid #E9EFF2;
  }
  .customer__orders tbody tr {
    margin-bottom: 0;
    border-bottom: none;
  }
  .customer__orders td[data-title]:before,
.customer__orders .table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
  .customer__orders .customer__orders__row.items + .overview td:first-child {
    border-top: 1px solid #E9EFF2;
  }
  .customer__orders .customer__orders__row.items + .overview td:first-child .title-value-item__title {
    padding-top: 1.5rem;
  }
}
.customer__orders td,
.customer__orders th {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 48em) {
  .customer__orders tr > td:last-child,
.customer__orders tr > th:last-child {
    text-align: right;
  }
  .customer__orders tr > td[colspan="4"] {
    text-align: left;
  }
}
@media screen and (min-width: 48em) {
  .customer__orders .title-value-item__title {
    display: none;
  }
}
.customer__orders .title-value-item__value {
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 48em) {
  .customer__orders .title-value-item__value {
    padding-bottom: 0;
    padding-left: 0;
  }
}
.customer__orders .customer__orders__row.items .title-value-item__title {
  display: block;
  text-transform: none;
  margin-bottom: 0.75rem;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
}
@media screen and (min-width: 62em) {
  .customer__orders .customer__orders__row.items .title-value-item__title {
    font-size: 2.1rem;
  }
}
@media screen and (min-width: 62em) {
  .customer__orders .customer__orders__row.items .title-value-item__title {
    font-size: 1.8rem;
  }
}
.customer__orders .customer__orders__row.items ul {
  list-style-type: none;
}
@media screen and (min-width: 48em) {
  .customer__orders .customer__orders__row.items + .overview td {
    border-top: 1px solid #E9EFF2;
  }
}

/* ---- Manage customer card ---- */
.customer__cards {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
}

.customer__card {
  padding: 15px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #6A6D73;
}
.customer__card > *:last-child {
  margin-bottom: 0;
}
.customer__card__info__title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

/* products */
.product-tile__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0 -10.5px;
}
@media screen and (min-width: 35em) {
  .product-tile__list {
    margin: 0 -13.5px;
  }
}
@media screen and (min-width: 48em) {
  .product-tile__list {
    margin: 0 -22.5px;
  }
}
.product-tile__list--no-results {
  margin-left: auto;
  margin-right: auto;
}

.product-tile__item {
  padding-left: 10.5px;
  padding-right: 10.5px;
  margin-bottom: 21px;
  flex: 0 0 100%;
}
@media screen and (min-width: 22.5em) {
  .product-tile__item {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 35em) {
  .product-tile__item {
    padding-left: 13.5px;
    padding-right: 13.5px;
    margin-bottom: 27px;
  }
}
@media screen and (min-width: 48em) {
  .product-tile__item {
    padding-left: 22.5px;
    padding-right: 22.5px;
    margin-bottom: 45px;
  }
}
@media screen and (min-width: 75em) {
  .product-tile__item {
    flex: 0 0 25%;
  }
}

.product-tile__slider .product-tile__list {
  flex-wrap: nowrap;
  padding-left: 6vw;
  padding-right: 6vw;
  padding-bottom: 36px;
  margin: 0;
}
@media screen and (min-width: 48em) {
  .product-tile__slider .product-tile__list {
    margin: 0;
    padding-left: 4vw;
    padding-right: 4vw;
  }
}
.product-tile__slider .product-tile__item {
  scroll-snap-align: center;
  flex: 0 0 180px;
}
@media screen and (min-width: 30em) {
  .product-tile__slider .product-tile__item {
    flex: 0 0 232px;
  }
}
@media screen and (min-width: 35em) {
  .product-tile__slider .product-tile__item {
    flex: 0 0 280px;
  }
}
@media screen and (min-width: 75em) {
  .product-tile__slider .product-tile__item {
    flex: 0 0 25%;
  }
}
.product-tile__slider .slider__arrows {
  top: 0;
  bottom: 0;
  height: auto;
  width: 12vw;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .product-tile__slider .slider__arrows {
    width: 8vw;
  }
}
.product-tile__slider .slider__arrows.previous {
  right: auto;
  left: 0;
  background: transparent;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0.8) 100%);
}
.product-tile__slider .slider__arrows.next {
  left: auto;
  right: 0;
  background: transparent;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0.8) 100%);
}
@media screen and (min-width: 75em) {
  .product-tile__slider[data-item-count="4"] .slider__arrows {
    display: none;
  }
  .product-tile__slider[data-item-count="4"] .slider-dots {
    display: none;
  }
}

@supports (display: grid) {
  .product-tile__list.grid {
    margin: 0;
    display: grid;
    grid-gap: 21px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  @media screen and (min-width: 35em) {
    .product-tile__list.grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 27px;
    }
  }
  @media screen and (min-width: 48em) {
    .product-tile__list.grid {
      margin: 0;
      grid-gap: 45px;
    }
  }
  @media screen and (min-width: 62em) {
    .product-tile__list.grid {
      grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
    }
  }
}
@supports (display: grid) {
  .product-tile__list.grid .product-tile__item {
    flex: 0 0 auto;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  @media screen and (min-width: 48em) {
    .product-tile__list.grid .product-tile__item {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

.product-tile {
  position: relative;
  display: block;
  text-decoration: none;
  color: #1D1E26;
}
.product-tile__image {
  background-color: #F0F4F6;
  position: relative;
  margin-bottom: 1.5rem;
}
.product-tile__image .image-overlay {
  position: absolute;
  left: -15px;
  bottom: 15px;
}
@media screen and (min-width: 62em) {
  .product-tile__image .image-overlay {
    left: -21px;
    bottom: 21px;
  }
}
.product-tile__image__flags {
  position: absolute;
  left: 9px;
  top: 9px;
  z-index: 1;
}
.product-tile__image__flags .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
.product-tile__image__flags .sale {
  background-color: #364896;
  color: #ffffff;
}
@media screen and (min-width: 30em) {
  .product-tile__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.product-tile__heading {
  line-height: normal;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}
@media screen and (min-width: 30em) {
  .product-tile__heading {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .product-tile__heading {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .product-tile__heading {
    letter-spacing: 0.25em;
  }
}
.product-tile__heading a {
  text-decoration: none;
}
.product-tile__price {
  color: #6A6D73;
  margin: 0.5em 0;
  line-height: 1.1;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 30em) {
  .product-tile__price {
    flex: 0 0 auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .product-tile__price {
    margin: 0;
    line-height: 2;
  }
}
.product-tile__price.image-overlay {
  margin-top: 0;
  display: none;
}
@media screen and (min-width: 35em) {
  .product-tile__price.image-overlay {
    display: block;
  }
}
.product-tile__price.image-overlay .quote {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-tile__price.image-overlay .quote {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-tile__price.image-overlay .original-price {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #1B244A;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-tile__price.image-overlay .original-price {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-tile__price.image-overlay .sale-price {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #1B244A;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-tile__price.image-overlay .sale-price {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-tile__price.image-overlay .sale-price__pretitle {
  font-size: 66.666%;
  letter-spacing: normal;
  top: 0;
}
.product-tile__price.image-overlay .sale-price + .original-price {
  display: inline-block;
  font-size: 0.96rem;
  background-color: #364896;
  padding: 6px 12px;
  position: absolute;
  bottom: calc(100% - 6px);
  left: 9px;
  line-height: normal;
}
@media screen and (min-width: 62em) {
  .product-tile__price.image-overlay .sale-price + .original-price {
    font-size: 75%;
  }
}
.product-tile__price .sale-price + .original-price {
  text-decoration: line-through;
  order: 0;
  font-size: 1.2rem;
}
.product-tile__price .sale-price {
  flex: 0 0 100%;
  order: 1;
}
.product-tile__price .sale-price__pretitle {
  top: 0;
  font-size: 0.96rem;
}
@media screen and (min-width: 62em) {
  .product-tile__price .sale-price__pretitle {
    font-size: 75%;
  }
}
.product-tile .product__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.product-tile .product__options .available-options {
  flex: 1 1 100%;
  max-width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #9c9c9c;
}
.product-tile__cta {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
  line-height: 2;
}
.product-tile__cta svg path {
  fill: #1B244A;
}
.product-tile__cta:hover, .product-tile__cta:focus, .product-tile__cta:active {
  color: #1D1E26;
}
.product-tile__cta:hover svg path, .product-tile__cta:focus svg path, .product-tile__cta:active svg path {
  fill: #1D1E26;
}
.product-tile__cta:after {
  content: " >";
}
.product-tile .product-tile__image .product-tile__cta {
  display: none;
}
@media screen and (min-width: 75em) {
  .product-tile .product-tile__image .product-tile__cta {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 0.5em);
  }
}

.hover-cta:hover {
  color: #1D1E26;
}
@media screen and (min-width: 75em) {
  .hover-cta:hover .product__picture {
    opacity: 0.5;
  }
}
@media screen and (min-width: 75em) {
  .hover-cta:hover .product-tile__image .product-tile__cta {
    display: block;
    animation: fadeInUp 300ms ease-in-out;
    animation-fill-mode: both;
  }
}
.hover-cta:focus .product__picture {
  opacity: 0.5;
}
@media screen and (min-width: 75em) {
  .hover-cta .product-tile__cta {
    display: none;
  }
  .hover-cta .product-tile__options {
    flex: 0 0 auto;
    order: 1;
  }
}

@media screen and (min-width: 35em) {
  .price-overlay .product-tile__price {
    display: none;
  }
}
@media screen and (min-width: 35em) {
  .price-overlay .product-tile__price.image-overlay {
    display: block;
  }
}

@media screen and (min-width: 48em) {
  .js .js-animate-section .featured-products__header {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section .product-tile__item:nth-child(-n+4) {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .featured-products__header {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 48em) {
  .js .js-animate-section.animate-section .product-tile__item:nth-child(-n+4) {
    opacity: 1;
    transform: translateY(0);
  }
}
.js .js-animate-section.animate-section .product-tile__item:nth-child(1) {
  transition-delay: 250ms;
}
.js .js-animate-section.animate-section .product-tile__item:nth-child(2) {
  transition-delay: 500ms;
}
.js .js-animate-section.animate-section .product-tile__item:nth-child(3) {
  transition-delay: 750ms;
}
.js .js-animate-section.animate-section .product-tile__item:nth-child(4) {
  transition-delay: 1000ms;
}

.product-card {
  position: relative;
  display: block;
  text-decoration: none;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #F0F4F6;
  margin-bottom: 27px;
}
@media screen and (min-width: 35em) {
  .product-card {
    display: flex;
  }
}
@media screen and (min-width: 48em) {
  .product-card {
    margin-bottom: 45px;
  }
}
.product-card__image {
  background-color: #F0F4F6;
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 35em) {
  .product-card__image {
    flex: 0 0 calc(50% - 54px);
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .product-card__image {
    flex: 0 0 calc(50% - 90px);
  }
}
@media screen and (min-width: 75em) {
  .product-card__image {
    flex: 0 0 400px;
  }
}
.product-card__image .image-overlay {
  position: absolute;
  left: -15px;
  bottom: 15px;
}
@media screen and (min-width: 62em) {
  .product-card__image .image-overlay {
    left: -21px;
    bottom: 21px;
  }
}
.product-card__image__flags {
  position: absolute;
  left: 9px;
  top: 9px;
  z-index: 1;
}
.product-card__image__flags .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
.product-card__image__flags .sale {
  background-color: #364896;
  color: #ffffff;
}
.product-card__picture {
  display: block;
  background-color: #F0F4F6;
  margin-bottom: 0;
  transition: opacity 300ms ease, mix-blend-mode 300ms ease;
}
@supports (aspect-ratio: 3/4) {
  .product-card__picture {
    aspect-ratio: 3/4;
  }
}
@supports (mix-blend-mode: multiply) {
  .product-card__picture__img {
    mix-blend-mode: multiply;
  }
}
@supports (object-fit: contain) {
  .product-card__picture .product-card__picture__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.product-card__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 27px;
}
@media screen and (min-width: 48em) {
  .product-card__content {
    padding: 45px;
  }
}
@media screen and (min-width: 35em) {
  .product-card__content p {
    margin-bottom: 0.5em;
  }
}
.product-card__heading {
  line-height: normal;
  margin-bottom: 0.5em;
  font-size: 2.7rem;
  color: #1D1E26;
}
@media screen and (min-width: 35em) {
  .product-card__heading {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 48em) {
  .product-card__heading {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .product-card__heading {
    letter-spacing: 0.25em;
  }
}
.product-card__price {
  line-height: 1.1;
  margin-top: auto;
  color: #1D1E26;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
@media screen and (min-width: 30em) {
  .product-card__price {
    flex: 0 0 auto;
    max-width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .product-card__price {
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 87.5em) {
  .product-card__price {
    letter-spacing: 0.25em;
  }
}
.product-card__price__quantity {
  font-size: 1.8rem;
}
@media screen and (min-width: 35em) {
  .product-card__price__quantity {
    font-size: 2.7rem;
  }
}
.product-card__price.image-overlay {
  font-size: 1.5rem;
  margin-top: 0;
  display: none;
}
@media screen and (min-width: 35em) {
  .product-card__price.image-overlay {
    display: block;
  }
}
.product-card__price.image-overlay .quote {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-card__price.image-overlay .quote {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-card__price.image-overlay .original-price {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #1B244A;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-card__price.image-overlay .original-price {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-card__price.image-overlay .sale-price {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #1B244A;
  display: inline-block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 62em) {
  .product-card__price.image-overlay .sale-price {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}
.product-card__price.image-overlay .sale-price__pretitle {
  font-size: 66.666%;
  letter-spacing: normal;
  top: 0;
}
.product-card__price.image-overlay .sale-price + .original-price {
  display: inline-block;
  font-size: 0.96rem;
  background-color: #364896;
  padding: 6px 12px;
  position: absolute;
  bottom: calc(100% - 6px);
  left: 9px;
  line-height: normal;
}
@media screen and (min-width: 62em) {
  .product-card__price.image-overlay .sale-price + .original-price {
    font-size: 75%;
  }
}
.product-card__price .sale-price + .original-price {
  text-decoration: line-through;
  order: 0;
}
.product-card__price .sale-price {
  flex: 0 0 100%;
  order: 1;
}
.product-card__price .sale-price__pretitle {
  top: 0;
  font-size: 0.96rem;
}
@media screen and (min-width: 62em) {
  .product-card__price .sale-price__pretitle {
    font-size: 75%;
  }
}
.product-card .product-card__content .product-card__price {
  margin-bottom: 0;
}
.product-card .product__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 1.5rem;
}
.product-card .product__options .available-options {
  flex: 1 1 100%;
  max-width: 100%;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
  color: inherit;
}
.product-card .colour-options-list.with-label {
  font-size: 1.5rem;
}
.product-card .size-options-list li {
  font-size: 1.5rem;
  color: inherit;
}
.product-card__cta {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #1B244A;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  padding: 0;
  line-height: 2;
}
.product-card__cta svg path {
  fill: #1B244A;
}
.product-card__cta:hover, .product-card__cta:focus, .product-card__cta:active {
  color: #1D1E26;
}
.product-card__cta:hover svg path, .product-card__cta:focus svg path, .product-card__cta:active svg path {
  fill: #1D1E26;
}
.product-card__cta:after {
  content: " >";
}
.product-card .product-card__image .product-card__cta {
  display: none;
}
@media screen and (min-width: 75em) {
  .product-card .product-card__image .product-card__cta {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 0.5em);
  }
}
.product-card__image-slider {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media screen and (min-width: 75em) {
  .hover-cta .product-card__cta {
    display: none;
  }
  .hover-cta .product-card__options {
    flex: 0 0 auto;
    order: 1;
  }
}

@media screen and (min-width: 35em) {
  .price-overlay .product-card__price {
    display: none;
  }
}
@media screen and (min-width: 35em) {
  .price-overlay .product-card__price.image-overlay {
    display: block;
  }
}

.product-card__image-slider .product-card__picture {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-card__image-slider .product-card__picture img {
  object-fit: contain;
}

.product-card__garment-template {
  margin-top: auto;
  margin-bottom: 1em;
}
.product-card__garment-template__title {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  display: block;
}
@media screen and (min-width: 62em) {
  .product-card__garment-template__title {
    font-size: 1.5rem;
  }
}
.product-card__garment-template__button-wrap {
  display: block;
  margin-top: 0.333em;
}

.product-card__sizing .text-link {
  text-align: left;
}

.product-list__item {
  line-height: normal;
  margin-bottom: 1.5rem;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
}
.product-list__item__title {
  font-weight: 700;
  margin-bottom: 0;
}
.product-list__item__variant {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.product-list__item__variant .colour-options__dot {
  margin-right: 0.33em;
}
.product-list__item__column {
  padding-left: 5px;
  padding-right: 5px;
}
.product-list__item__column.image {
  flex: 0 0 60px;
  max-width: 60px;
  display: none;
}
@media screen and (min-width: 30em) {
  .product-list__item__column.image {
    display: block;
  }
}
.product-list__item__column.lhs {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.product-list__item__column.rhs {
  flex: 0 0 auto;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.product-list__item__img {
  display: block;
  background-color: #F0F4F6;
}
@supports (aspect-ratio: 9/16) {
  .product-list__item__img {
    aspect-ratio: 9/16;
  }
  @media screen and (min-width: 62em) {
    .product-list__item__img {
      aspect-ratio: 103/120;
    }
  }
}
.product-list__item__img img {
  margin: auto;
}
@supports (aspect-ratio: 9/16) {
  .product-list__item__img img {
    object-fit: contain;
    object-position: center;
    height: 100%;
  }
}
.product-list__item__quantity {
  margin-bottom: 0;
}
.product-list__item__quantity .preceding {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-transform: uppercase;
}
.product-list__item__cost {
  margin-bottom: 0;
}
.product-list__item__cost .price {
  display: block;
}
.product-list__item__cost .price--original {
  font-size: 1.2rem;
}
.product-list__item__cost .price--original .original {
  text-decoration: line-through;
}
.product-list__item__cost .price--current {
  font-weight: 700;
  color: #1D1E26;
}
.product-list__item__flags {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.product-list__item__flags .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 0 5px;
  padding-left: calc(5px + 0.25em);
}
.product-list__item__flags .sale {
  background-color: #364896;
  color: #ffffff;
}

.product-page__overview {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .product-page__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 5.111111vw;
  }
}
@media screen and (min-width: 100em) {
  .product-page__grid {
    grid-gap: 80px;
  }
}
.product-page__details {
  padding-bottom: 48px;
}
@media screen and (min-width: 48em) {
  .product-page__details {
    align-self: start;
    grid-row: span 3;
    padding-bottom: 0;
  }
}
.product-page__details__heading {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.2em;
  margin-right: 1em;
  color: #2b2b2b;
}
@media screen and (min-width: 35em) {
  .product-page__details__heading {
    font-size: 2.1rem;
  }
}
.product-page__details__product-code {
  text-transform: uppercase;
  font-size: 1.2rem;
}
.product-page__details__selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.product-page__details__selection__title {
  font-size: 1.8rem;
  margin-bottom: 0.2em;
  margin-right: 1em;
  color: #2b2b2b;
}
@media screen and (min-width: 35em) {
  .product-page__details__selection__title {
    font-size: 2.1rem;
  }
}
.product-page__media {
  align-self: start;
}
.product-page__image {
  position: relative;
}
.product-page__image__flags {
  position: absolute;
  left: 9px;
  top: 9px;
  z-index: 1;
}
.product-page__image__flags .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
.product-page__image__flags .sale {
  background-color: #364896;
  color: #ffffff;
}
.product-page__image-slider {
  list-style-type: none;
  position: relative;
  width: 100%;
}
.product-page__image-slider .slider__dots {
  position: static;
  transform: none;
  justify-content: center;
}
.product-page__image-slider .slider__dots a {
  display: block;
  opacity: 0.5;
  border: 1px solid transparent;
  padding: 0;
  border-radius: 2px;
  transition: opacity 250ms ease, transform 250ms ease;
}
.product-page__image-slider .slider__dots a:before {
  content: none;
}
.product-page__image-slider .slider__dots a.active {
  opacity: 1;
  background-color: #F0F4F6;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}
.product-page__image-slider .slider__dots a:hover, .product-page__image-slider .slider__dots a:focus {
  opacity: 1;
}
.product-page__image-slider .slider__dots a img {
  pointer-events: none;
}
@supports (mix-blend-mode: multiply) {
  .product-page__image-slider .slider__dots a img {
    mix-blend-mode: multiply;
  }
}
.product-page__image-slider .slider__arrows {
  z-index: 1;
}
.product-page__image-slider .slider__arrows.previous {
  left: 6vw;
  right: auto;
}
@media screen and (min-width: 48em) {
  .product-page__image-slider .slider__arrows.previous {
    left: auto;
    right: 100%;
  }
}
.product-page__image-slider .slider__arrows.next {
  right: 6vw;
  left: auto;
}
@media screen and (min-width: 48em) {
  .product-page__image-slider .slider__arrows.next {
    right: auto;
    left: 100%;
  }
}
.product-page__image__picture {
  display: block;
}
.product-page__image__slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.product-page__image__slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.product-page__image__slider__item {
  flex: 0 0 100%;
  scroll-snap-align: start;
}
.product-page__image__slider__item__inner {
  display: block;
  position: relative;
}
.product-page__supplier-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px 1.5rem;
}
.product-page__supplier-logo {
  display: block;
  flex: 0 0 auto;
  padding: 9px;
  max-height: 64px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin: 2px;
}
.product-page__purchase {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
}
.product-page__purchase > * {
  flex: 0 0 auto;
}
.product-page__purchase__status {
  margin-bottom: 0;
  margin-right: 1em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
@media screen and (min-width: 48em) {
  .product-page__purchase__status {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 50em) {
  .product-page__purchase__status {
    /* 800px */
    margin-right: 1em;
  }
}
.product-page__purchase__status.sold-out {
  font-size: 200%;
}
@media screen and (min-width: 50em) {
  .product-page__purchase__status.sold-out {
    /* 800px */
    margin-right: 0;
  }
}
.product-page__purchase__back {
  text-align: right;
  margin-bottom: 0;
  flex: 1 1 100%;
  max-width: 100%;
}
.product-page__purchase__unavailable {
  flex: 0 0 100%;
}
.product-page__purchase.with-quantity > * {
  flex: 1 1 auto;
}
@media screen and (min-width: 62em) {
  .product-page__purchase.with-quantity > * {
    flex: 0 0 auto;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__status {
  flex-basis: 100%;
  margin-bottom: 0.5em;
  margin-right: 0;
  letter-spacing: 0.25em;
  color: #1D1E26;
}
@media screen and (min-width: 48em) {
  .product-page__purchase.with-quantity .product-page__purchase__status {
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (min-width: 62em) {
  .product-page__purchase.with-quantity .product-page__purchase__status {
    flex-basis: auto;
    margin-bottom: 0;
    margin-right: 1em;
    letter-spacing: 0.15em;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__status.sold-out {
  letter-spacing: 0.15em;
  color: inherit;
  margin-bottom: 0;
}
@media screen and (min-width: 35em) {
  .product-page__purchase.with-quantity .product-page__purchase__status.sold-out {
    flex: 0 0 auto;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__back {
  text-align: left;
}
@media screen and (min-width: 35em) {
  .product-page__purchase.with-quantity .product-page__purchase__back {
    text-align: right;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__quantity {
  margin-bottom: 0;
  flex-basis: 60px;
}
@media screen and (min-width: 62em) {
  .product-page__purchase.with-quantity .product-page__purchase__quantity {
    flex-basis: auto;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__quantity .field-input input {
  padding-right: 0;
  line-height: normal;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media screen and (min-width: 62em) {
  .product-page__purchase.with-quantity .product-page__purchase__quantity .field-input input {
    width: 60px;
  }
}
.product-page__purchase.with-quantity .product-page__purchase__quantity + .button {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.product-page .price {
  margin-bottom: 0;
  line-height: normal;
}
.product-page .price--current {
  margin-left: auto;
}
.product-page .price--current .price__quantity {
  font-size: 200%;
  color: #1B244A;
}
.product-page .price--original .price__preceding {
  font-size: 75%;
}
.product-page__price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.5rem;
}
.product-page__price .flag {
  line-height: normal;
  padding: 3px 6px;
  padding-left: calc(6px + 0.25em);
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
}
.product-page__price .sale {
  background-color: #364896;
  color: #ffffff;
}
.product-page__price p {
  margin-bottom: 0;
}
.product-page__price .flag + .price--original {
  margin-top: 0.25em;
  margin-bottom: calc((1.5rem * 2) * 0.2);
}
.product-page__price--sold-out .product-page__purchase__status {
  line-height: normal;
}
.product-page__price--sold-out .price--current {
  margin-left: 0;
}
.product-page .image-not-available {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  font-size: 1.5rem;
  padding: 8px 40px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
}
@media screen and (min-width: 48em) {
  .product-page .image-not-available {
    /* 768px */
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 62em) {
  .product-page .image-not-available {
    /* 992px */
    font-size: 1.5rem;
  }
}
.product-page .image-not-available span {
  display: block;
}
.product-page .image-not-available .not-available__variant {
  font-weight: 700;
}
.product-page .product__options .available-options {
  margin-bottom: 0.5em;
}
.product-page .product__options .size-options-list li {
  font-size: 1.5rem;
}
.product-page__section {
  align-self: start;
}
.product-page__introduction {
  border-bottom: 1px solid #F0F4F6;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 48em) {
  .product-page__introduction {
    padding-bottom: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
.product-page__introduction p, .product-page__introduction ul, .product-page__introduction ol, .product-page__introduction blockquote {
  margin-bottom: 0.5em;
}
.product-page__options {
  padding: 1.5rem 0;
}
.product-page__options.line-separators {
  margin: 1.5rem 0;
  border-bottom: 1px solid #F0F4F6;
  border-top: 1px solid #F0F4F6;
}
.product-page__options__title {
  color: #1B244A;
}
.product-page__options__intro {
  font-size: 1.2rem;
}
.product-page__options__personalise-name .fieldset-input {
  margin-top: 2px;
}
.product-page__options__personalise-name .field-input input {
  padding-top: 6px;
  padding-bottom: 6px;
}

.product__size-chart.muliple-charts {
  padding: 15px;
  border: 1px solid #F0F4F6;
  border-radius: 3px;
  margin-bottom: 1.5rem;
  border-left: 3px solid #6A6D73;
}
.product__size-chart.muliple-charts > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .product__size-chart.muliple-charts {
    padding: 21px;
  }
}

.product-page__sizing h4 {
  margin-bottom: 0;
  margin-top: 1em;
  font-size: 1.2rem;
}
.product-page__sizing:first-child h4 {
  margin-top: 0;
}
.product-page__sizing table {
  background-color: #F0F4F6;
  border-color: #ffffff;
}
.product-page__sizing table th,
.product-page__sizing table td {
  border: 2px solid #ffffff;
}

.product__picture-link {
  display: block;
}

.product__picture {
  display: block;
  position: relative;
  background-color: #F0F4F6;
  margin-bottom: 0;
  transition: opacity 300ms ease, mix-blend-mode 300ms ease;
}
@supports (aspect-ratio: 3/4) {
  .product__picture {
    aspect-ratio: 3/4;
  }
}
@supports (mix-blend-mode: multiply) {
  .product__picture__img {
    mix-blend-mode: multiply;
  }
}
@supports (object-fit: contain) {
  .product__picture__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
@supports (filter: grayscale(1)) {
  .product__picture__img.no-image {
    filter: grayscale(1);
    opacity: 0.8;
  }
}
.product__picture.placeholder {
  align-items: center;
}

.product__image-slider {
  position: relative;
}
.product__image-slider__track {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.product__image-slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.product__image-slider .slider__arrows {
  z-index: 10;
}
@media screen and (min-width: 30em) {
  .product__image-slider .slider__arrows {
    width: 36px;
    height: 36px;
    top: calc(50% - 18px);
  }
}
.product__image-slider .slider__arrows.previous {
  right: calc(100% - 32px);
}
.product__image-slider .slider__arrows.next {
  left: calc(100% - 32px);
}
.product__image-slider .slider__dots {
  display: none;
}

.product__image-slider .product__picture-link {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.product__image-slider .product__picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product__image-slider .product__picture img {
  object-fit: contain;
}

.product__image-flag.flag--colour {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
}
.product__image-flag.flag--colour .badge {
  font-size: inherit;
}
.product__image-flag.flag--colour .product__image-flag__colour {
  margin-bottom: 0.25em;
}
.product__image-flag.flag--colour .product__image-flag__subtitle {
  display: block;
  margin-bottom: 0;
  font-weight: normal;
}
.product__image-flag.flag--colour .size-options-list {
  display: block;
  margin: 0.15em auto 0;
}
.product__image-flag.flag--colour .size-options-list li {
  color: inherit;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: inherit;
}
.product__image-flag.flag--no-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  font-size: 75%;
  margin-top: 1.125rem;
  padding: 8px 40px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
}

.bulk-pricing {
  margin-top: 1.5rem;
}

.bulk-pricing__colour-picker select {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}

.bulk-pricing__title {
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
  color: #2b2b2b;
  font-size: 1.5rem;
}
@media screen and (min-width: 62em) {
  .bulk-pricing__title {
    font-size: 1.5rem;
  }
}
.bulk-pricing__title.no-bottom-margin {
  margin-bottom: 0;
}

.bulk-pricing__tier-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.bulk-pricing__tier-quantity .tier-quantity__item-total {
  flex: 0 0 auto;
  margin-bottom: 0;
  margin-right: 1em;
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  transition: margin-top 250ms ease-in-out;
}
@media screen and (min-width: 48em) {
  .bulk-pricing__tier-quantity .tier-quantity__item-total {
    letter-spacing: normal;
  }
}
@media screen and (min-width: 75em) {
  .bulk-pricing__tier-quantity .tier-quantity__item-total {
    letter-spacing: 0.15em;
  }
}
.bulk-pricing__tier-quantity .tier-quantity__item-total__title {
  margin-bottom: 0;
  line-height: 1.5;
  padding: 0.2em 0 0.25em;
}
.bulk-pricing__tier-quantity .tier-quantity__item-total .label-long {
  display: none;
}
@media screen and (min-width: 75em) {
  .bulk-pricing__tier-quantity .tier-quantity__item-total .label-long {
    display: inline;
  }
}
@media screen and (min-width: 75em) {
  .bulk-pricing__tier-quantity .tier-quantity__item-total .label-condensed {
    display: none;
  }
}

.bulk-pricing__tiers {
  flex: 0 0 200px;
  max-width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 62em) {
  .bulk-pricing__tiers {
    flex-basis: 250px;
  }
}
.bulk-pricing__tiers > *:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.bulk-pricing__tiers > *:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bulk-pricing__tier {
  padding: 0.2em 1em 0.25em;
  border: 1px solid #F0F4F6;
  margin-bottom: 0;
  margin-top: -1px;
  display: flex;
  justify-content: space-between;
}
.bulk-pricing__tier.headings {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.25em;
  line-height: 23px;
}
.bulk-pricing__tier.headings .bulk-pricing__quantity {
  font-weight: 400;
}
.bulk-pricing__tier.headings .bulk-pricing__quantity .label-long {
  display: none;
}
@media screen and (min-width: 75em) {
  .bulk-pricing__tier.headings .bulk-pricing__quantity .label-long {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .bulk-pricing__tier.headings .bulk-pricing__quantity .label-condensed {
    display: none;
  }
}

.bulk-pricing__quantity {
  display: block;
  font-weight: 700;
  text-align: right;
}

.bulk-pricing__price {
  display: block;
  text-align: left;
}

.bulk-pricing__variants-selector {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 1.5rem;
}
@supports (display: grid) {
  .bulk-pricing__variants-selector {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    margin-left: 0;
    margin-right: 0;
  }
}
@supports (display: grid) {
  .bulk-pricing__variants-selector.single {
    grid-template-columns: repeat(auto-fit, minmax(40px, 100%));
  }
}

.bulk-pricing__variant {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  flex: 0 0 auto;
  max-width: 100%;
}
@supports (display: grid) {
  .bulk-pricing__variant {
    padding-left: 0;
    padding-right: 0;
  }
}
.bulk-pricing__variant-title {
  display: block;
}
.bulk-pricing__variant-quantity {
  display: block;
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  padding: 12px 16px;
  -webkit-appearance: none;
  line-height: 1.4;
  border: 1px solid #E9EFF2;
  background-color: #F0F4F6;
  text-align: center;
  padding: 6px 0 6px 12px;
  border-radius: 4px;
}
.bulk-pricing__variant-quantity input::-webkit-outer-spin-button,
.bulk-pricing__variant-quantity input::-webkit-inner-spin-button {
  margin-left: 6px;
}
.bulk-pricing__variant-quantity input[type=number] {
  -moz-appearance: textfield;
}
.bulk-pricing__variant-total {
  display: block;
  font-size: 1.2rem;
}

.bulk-pricing__total {
  text-transform: uppercase;
}

.bulk-pricing__minimum-quantity {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
}
.bulk-pricing__minimum-quantity--highlight {
  font-weight: 700;
}

@media screen and (min-width: 30em) {
  .product__options {
    flex: 0 0 auto;
    max-width: 100%;
  }
}
.product__options .available-options {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.colour-options-list {
  flex: 0 0 100%;
  max-width: 100%;
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.colour-options-list::-webkit-scrollbar {
  display: none;
  width: 0;
}
.colour-options-list__item {
  margin-left: 1px;
  margin-right: 1px;
}
.colour-options-list__item.with-label {
  flex: 0 0 60px;
  width: 60px;
  margin-left: 0;
  margin-right: -1px;
  padding: 3px;
  border: 1px solid #F0F4F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
}
.colour-options-list__item.with-label .colour-options__dot {
  margin-bottom: 0.33em;
}
.colour-options-list__item:first-child {
  margin-left: 0;
}
.colour-options-list__item:last-child {
  margin-right: 0;
}
.colour-options-list__item[style="background: #ffffff; border-color: #ffffff;"] {
  border-color: #E9EFF2 !important;
}

.colour-options__dot {
  display: block;
  border: 1px solid #9c9c9c;
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
  border-radius: 100%;
}
.colour-options__dot[style="background: ; border-color: ;"] {
  display: none;
}
@media screen and (min-width: 75em) {
  .colour-options__dot {
    flex: 0 0 15px;
    width: 15px;
    height: 15px;
  }
}

.size-options-list {
  flex: 0 0 auto;
  list-style-type: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}
.size-options-list li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 1.2rem;
  color: #9c9c9c;
  font-weight: 700;
  margin-left: 3px;
  margin-right: 3px;
}
.size-options-list li:first-child {
  margin-left: 0;
}
.size-options-list li:last-child {
  margin-right: 0;
}

.colour-options-list--labels {
  margin-top: -1px;
}

.colour-options-list--labels + .size-options-list {
  margin-top: -1px;
}
.colour-options-list--labels + .size-options-list li {
  flex: 0 0 60px;
  width: 60px;
  padding: 3px;
  margin: 0;
  margin-right: -1px;
  border: 1px solid #F0F4F6;
}

/* product filtering  */
.filter__field input[type=checkbox],
.filter__field input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.filter__field input[type=checkbox].focusable:active, .filter__field input[type=checkbox].focusable:focus,
.filter__field input[type=radio].focusable:active,
.filter__field input[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.filter__field input[type=checkbox] + label,
.filter__field input[type=radio] + label {
  padding-left: 24px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* -- Field set pseudo elements -- */
.filter__field input[type=checkbox] + label:before,
.filter__field input[type=radio] + label:before {
  content: " ";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  position: relative;
  top: 1px;
  background-color: transparent;
  border: 1px solid #b8b8b8;
  margin-left: -24px;
}

/* Radio button (pseudo element) */
.filter__field input[type=radio] + label:before {
  border-radius: 12px;
}

/* -- Field set pseudo elements : checked -- */
.filter__field input[type=checkbox]:checked + label {
  color: #1D1E26;
}

.filter__field input[type=checkbox]:checked + label:before,
.filter__field input[type=radio]:checked + label:before {
  background-color: #1B244A;
  border-color: #1B244A;
}

.filter__field input[type=checkbox]:checked + label:after,
.filter__field input[type=radio]:checked + label:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 3px;
}

/* Checkbox box checked (pseudo element) */
.filter__field input[type=checkbox]:checked + label:after {
  content: " ";
  background-color: #1B244A;
  background-image: url(../assets/icon-system/icon_form_tick-sm-white.svg);
  background-position: left 1px top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 15px;
  height: 15px;
}

/* Radio dot (pseudo element) */
.filter__field input[type=radio]:checked + label:after {
  left: 7px;
  top: 11px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #4A4B51;
}

.filter__inner {
  position: relative;
  padding-bottom: 56px;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 48em) {
  .filter__inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.filter__inner .filter__product-types {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: normal;
}
.filter__inner .filter__product-types li {
  display: block;
}
.filter__inner .filter__product-types a {
  position: relative;
  display: inline-block;
  padding: 0;
  color: inherit;
  text-decoration: none;
  padding-top: 0.2666em;
  padding-bottom: 0.2666em;
  left: 0;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out, left 250ms ease-in-out;
}
.filter__inner .filter__product-types a:before {
  content: "> ";
}
.filter__inner .filter__product-types a:hover, .filter__inner .filter__product-types a:focus {
  color: #2b2b2b;
  left: 8px;
}
.filter__inner .filter__product-types .product-type-current-item {
  font-weight: 700;
  color: #1B244A;
}
.filter__inner .filter__option__list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.filter__inner .filter__option__list[aria-expanded=false] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.filter__inner .filter__option__list[aria-expanded=false].focusable:active, .filter__inner .filter__option__list[aria-expanded=false].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.filter__inner .filter__option__list[aria-expanded=true] {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  margin-top: 0.2666em;
}
.filter__inner .filter__option__list--depth2 {
  margin-left: 24px;
}
.filter__inner .filter__section {
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 2px solid #F0F4F6;
}
.filter__inner .filter__section__header {
  margin-top: 20px;
}
.filter__inner .filter__section__toggle {
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  padding: 0;
}
.filter__inner .filter__section__toggle__label {
  text-transform: uppercase;
  color: #2b2b2b;
  font-size: 1.5rem;
  line-height: normal;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
}
.filter__inner .filter__section__toggle__icon {
  flex: 0 0 40px;
  height: 1.2em;
}
.filter__inner .filter__section__toggle__icon:before, .filter__inner .filter__section__toggle__icon:after {
  content: " ";
  width: 12px;
  height: 2px;
  display: block;
  position: absolute;
  background-color: #4A4B51;
  margin: 0 auto;
  right: 20px;
  top: 50%;
  transition: color 400ms ease, background-color 400ms ease, transform 400ms ease, top 400ms ease;
}
.filter__inner .filter__section__toggle__icon:hover:before, .filter__inner .filter__section__toggle__icon:focus:before, .filter__inner .filter__section__toggle__icon:hover:after, .filter__inner .filter__section__toggle__icon:focus:after {
  background-color: #0D0800;
}
.filter__inner .filter__section__toggle__icon:before {
  transform: rotate(45deg) translateX(-5px);
}
.filter__inner .filter__section__toggle__icon:after {
  transform: rotate(-45deg) translateX(5px);
}
.filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:before, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:after {
  top: calc(50% - 6px);
  background-color: #2b2b2b;
}
.filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:hover, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:focus {
  color: #0D0800;
}
.filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:hover:before, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:hover:after, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:hover:before, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:hover:after, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:focus:before, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:focus:after, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:focus:before, .filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:focus:after {
  background-color: #0D0800;
}
.filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:before {
  transform: rotate(-45deg) translateX(-5px);
}
.filter__inner .filter__section__toggle[aria-pressed=true] .filter__section__toggle__icon:after {
  transform: rotate(45deg) translateX(5px);
}
.filter__inner .filter__section__input {
  display: block;
  width: 100%;
  outline: none;
  padding-top: 17px;
  padding-bottom: 15px;
  padding-right: 12px;
  -webkit-appearance: none;
  line-height: 1.4;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding-left: 36px;
  background-image: url(../assets/icon-system/icon_search.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center left;
  background-size: 24px, 40px;
}
.filter__inner .filter__section--product-types {
  padding-bottom: 1.5rem;
  display: none;
}
@media screen and (min-width: 48em) {
  .filter__inner .filter__section--product-types {
    display: block;
  }
}
.filter__inner .filter__section--search {
  padding-bottom: 0;
}
.filter__inner ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9c9c9c;
}
.filter__inner ::-moz-placeholder {
  /* Firefox 19+ */
  color: #9c9c9c;
}
.filter__inner :-ms-input-placeholder {
  /* IE 10+ */
  color: #9c9c9c;
}
.filter__inner :-moz-placeholder {
  /* Firefox 18- */
  color: #9c9c9c;
}

.filter__parameters {
  padding: 15px 0;
}

.filter__parameters__title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.125rem;
  color: #b8b8b8;
  margin-bottom: 0;
  letter-spacing: 2px;
}
@media screen and (min-width: 48em) {
  .filter__parameters__title {
    display: block;
  }
}

.filter__parameter {
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  background: #F0F4F6;
  color: #4A4B51;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  line-height: 1.2;
  font-size: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0);
  padding: 0 8px 0 3px;
  margin: 2px;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
.filter__parameter--product-type {
  padding: 3px 8px;
}

.filter__parameter-remove {
  display: inline-block;
  cursor: pointer;
  padding: 3px 5px 3px 5px;
}

.filter__parameter-remove:hover svg path {
  fill: #000000;
}

.button--load-more {
  margin: 20px 0;
}

.product-listing-row {
  margin-right: -27px;
  margin-left: -27px;
}
@media screen and (min-width: 100em) {
  .product-listing-row {
    margin-right: -36px;
    margin-left: -36px;
  }
}
.product-listing-row > * {
  padding-right: 27px;
  padding-left: 27px;
}
@media screen and (min-width: 100em) {
  .product-listing-row > * {
    padding-right: 36px;
    padding-left: 36px;
  }
}

.product-listing-filters-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  width: 100%;
}
.product-listing-filters-toggle:before {
  content: none;
}
.product-listing-filters-toggle svg {
  margin-right: 8px;
  width: 1em;
  height: 1em;
}
@media screen and (min-width: 48em) {
  .product-listing-filters-toggle {
    display: none;
  }
}
.product-listing-filters-toggle[aria-pressed=false] .svg-open-filter {
  transform: translateY(0);
}
.product-listing-filters-toggle[aria-pressed=false] .svg-close-filter {
  transform: translateY(100%);
}
.product-listing-filters-toggle[aria-pressed=true] .svg-open-filter {
  transform: translateY(100%);
}
.product-listing-filters-toggle[aria-pressed=true] .svg-close-filter {
  transform: translateY(0);
}
.product-listing-filters-toggle .svg-open-filter,
.product-listing-filters-toggle .svg-close-filter {
  transition: 250ms ease transform;
}
.product-listing-filters-toggle .product-filters-btn-label {
  transition: 250ms ease width;
}

@media screen and (max-width: 47.9375em) {
  .product-listing-filters[aria-expanded=false] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .product-listing-filters[aria-expanded=false].focusable:active, .product-listing-filters[aria-expanded=false].focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .product-listing-filters[aria-expanded=true] {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    width: auto;
    animation: attentionGrab 800ms cubic-bezier(0.76, 0.19, 0.68, 0.9) forwards;
  }
}

/* Fade in */
@-webkit-keyframes attentionGrab {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.06);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes attentionGrab {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.06);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
.product-listing-filters-trigger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #BCAD98;
  border-color: #BCAD98;
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), 0 2px 24px rgba(0, 0, 0, 0.2);
}
.product-listing-filters-trigger:before {
  content: none;
}
@media screen and (min-width: 48em) {
  .product-listing-filters-trigger {
    display: none;
  }
}

.search-results__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  grid-column: span 3;
}
.search-results__loading svg path {
  fill: #4A4B51;
}
.search-results__loading__inner {
  text-align: center;
}

.search-results__loading__title {
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 1px;
}

.search-results__results {
  background-color: #F0F4F6;
  padding: 27px;
  grid-column: span 3;
  width: 100%;
  text-align: center;
  color: #2b2b2b;
}
.search-results__results__heading {
  display: block;
  color: #ffffff;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  letter-spacing: 0.25em;
  font-size: 1.2rem;
  padding: 9px 15px;
  background-color: #571E25;
  margin-bottom: 27px;
  display: inline-block;
}
@media screen and (min-width: 62em) {
  .search-results__results__heading {
    font-size: 1.5rem;
    padding: 12px 21px;
  }
}

/* progress bar */
/* ---- Progress steps ---- */
.steps {
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #F0F4F6;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  clear: both;
}

.steps h3 {
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.25em;
}
@media screen and (min-width: 87.5em) {
  .steps h3 {
    /* 1400px */
    font-size: 1.5rem;
  }
}

.steps__list {
  position: relative;
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.steps__item {
  position: relative;
  display: inline-block;
  text-align: center;
  z-index: 2;
  box-sizing: border-box;
  flex: 0 0 50%;
  margin-bottom: 10px;
}

.steps__item .title {
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  padding-top: 4px;
}
@media only screen and (min-width: 62em) {
  .steps__item .title {
    /* 992px */
  }
  .steps__item .title .steps__item .title {
    font-size: 1.1rem;
  }
}

.steps__item a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.steps__item .dot {
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  color: #7f7f7f;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  border-radius: 100%;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 7px 0 5px;
  z-index: 1;
}

.steps__item.populated .dot {
  border-color: #6A6D73;
  background-color: #6A6D73;
  color: #ffffff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.steps__item.active .dot {
  border-color: #1B244A;
  background-color: #1B244A;
  color: #ffffff;
  opacity: 1;
}

.deactive {
  opacity: 0.33;
}

/* BP MAX Smaller */
@media screen and (max-width: 42.4375em) {
  /* 679px */
  .steps__item {
    height: 55px;
  }

  .steps__item:nth-child(2n+1) .dot:before {
    content: " ";
    position: absolute;
    top: -7px;
    left: 0;
    right: 0;
    border-left: 2px solid #6A6D73;
    border-top: 2px solid #6A6D73;
    border-bottom: 2px solid #6A6D73;
    height: 25px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: -1;
  }

  .steps__item:nth-child(2n+2) .dot:before {
    content: " ";
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    border-right: 2px solid #6A6D73;
    border-top: 2px solid #6A6D73;
    border-bottom: 2px solid #6A6D73;
    height: 44px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: -1;
  }

  .steps__item:first-child .dot:before {
    border-top: none;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .steps__item:first-child .dot:after {
    content: " ";
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    height: 8px;
    width: 8px;
    background-color: #6A6D73;
    border-radius: 4px;
  }

  .steps__item:last-child .dot:before {
    border-right: none;
    border-top: 2px solid #6A6D73;
    border-bottom: none;
    border-top-right-radius: 0;
  }

  .steps__item:nth-child(odd):last-child .dot:before {
    border-bottom: 2px solid #6A6D73;
  }

  .steps__item:last-child .dot:after {
    content: " ";
    position: absolute;
    top: calc(50% - 15px);
    left: calc(100% - 8px);
    height: 8px;
    width: 8px;
    background-color: #6A6D73;
    border-radius: 4px;
  }
}
/* BP Smaller */
@media screen and (min-width: 42.5em) {
  /* 680px */
  .steps {
    overflow: hidden;
  }

  .steps__list {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .steps__item {
    position: relative;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 88px;
    min-width: 88px;
  }

  .steps__item:before,
.steps__item:after {
    content: " ";
    position: absolute;
    top: 17px;
    height: 2px;
    background-color: #1B244A;
    width: calc(23vw - 110px);
    z-index: 0;
  }

  .steps__item:before {
    right: 100%;
    left: auto;
  }

  .steps__item:after {
    left: 100%;
    right: auto;
  }

  .steps__item:first-child:before,
.steps__item:last-child:after {
    content: none;
  }

  .steps__item .dot {
    position: relative;
    width: 40px;
    height: 40px;
    padding-top: 12px;
    padding-bottom: 9px;
  }
}
@media only screen and (min-width: 48em) {
  /* 768px */
  .steps {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 62em) {
  /* 992px */
  .steps {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 100em) {
  /* 1600px */
  .steps__item:before,
.steps__item:after {
    width: 250px;
  }
}
/* vouchers */
.order__vouchers-listing {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}
@media screen and (min-width: 35em) {
  .order__vouchers-listing {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
@media screen and (min-width: 48em) {
  .order__vouchers-listing {
    gap: 3em;
  }
}

.voucher-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
@media screen and (min-width: 40em) {
  .voucher-summary {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}

.voucher-summary__image {
  flex: 0 0 auto;
}
@media screen and (min-width: 40em) {
  .voucher-summary__image {
    flex: 0 0 33.3333%;
    padding-right: 1em;
  }
}

.voucher-summary__content {
  flex: 1 1 auto;
}
@media screen and (min-width: 40em) {
  .voucher-summary__content {
    flex: 0 0 66.6666%;
    padding-left: 1em;
  }
}
.voucher-summary__content__title {
  color: #1B244A;
}
.voucher-summary__content__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.voucher-summary__content__grid .title-value-item {
  flex: 1 1 auto;
  padding-right: 10px;
}
.voucher-summary__content__grid .title-value-item:last-of-type {
  margin-bottom: 1em;
}
.voucher-summary__content__cta {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}
@media screen and (min-width: 40em) {
  .voucher-summary__content__cta {
    flex: 0 0 100%;
    grid-auto-flow: row;
    justify-content: initial;
  }
}
.voucher-summary__content__cta .button {
  flex: 1 1 auto;
}

.cart__voucher-code {
  margin-bottom: 0;
}
.cart__voucher-code__title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #1B244A;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 62em) {
  .cart__voucher-code__title {
    font-size: 2.1rem;
  }
}