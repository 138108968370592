.customer__orders {
    width: 100%;
    text-align: left;

    @media only screen and (max-width: $bp767) {
        display: block;
        border: none;

        tbody,
        tr {
            display: block;
            border: none;
        }
        thead,
        th {
            display: none;
        }
        tr {
            display: table;
            width: 100%;
        }
        tr.table--responsive__first-row {
            display: none;
        }
        td {
            display: table-row;
            padding: 0;
        }
        td[data-title]:before {
            content: attr(data-title);
            background-color: $color-grey-01;
            border-right: 1px solid $color-grey-02;
        }
        td[data-title] {
            border-left: 1px solid $color-grey-02;
            border-right: 1px solid $color-grey-02;
            border-top: 1px solid $color-grey-02;
        }
        tbody tr {
            margin-bottom: 0;
            border-bottom: none;
        }
        td[data-title]:before,
        .table--responsive__content {
            padding: 5px 15px;
            display: table-cell;
        }

        .customer__orders__row.items + .overview td:first-child {
            border-top: 1px solid $color-grey-02;

            .title-value-item__title {
                padding-top: $fs-body;
            }
        }
    }

    td,
    th {
        padding-left: 0;
        padding-right: 0;
    }

    tr {
        @include screen-768 {
            > td:last-child,
            > th:last-child {
                text-align: right;
            }

            > td[colspan="4"] {
                text-align: left;
            }
        }
    }

    .title-value-item__title {
        @include screen-768 {
            display: none;
        }
    }

    .title-value-item__value {
        padding-bottom: $fs-body;

        @include screen-768 {
            padding-bottom: 0;
            padding-left: 0;
        }
    }

    .customer__orders__row.items {
        .title-value-item__title {
            display: block;
            text-transform: none;
            margin-bottom: $fs-body*0.5;
            @include title-style-03;

            @include screen-992 {
                font-size: $fs-body*1.2;
            }
        }

        ul {
            list-style-type: none;
        }
    }

    .customer__orders__row.items + .overview td {
        @include screen-768 {
            border-top: 1px solid $color-grey-02;
        }
    }
}

/* ---- Manage customer card ---- */
.customer__cards {
    display: grid;
    grid-gap: $spacing--medium;
    grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
}

.customer__card {
    @include box('tiny');
    @include box-lined($color-grey-06);

    &__info__title {
        font-size: $fs-body;
        font-weight: $fw-bold;
        font-family: $primary-font;
    }
}



