.product-page {

    &__overview {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    &__grid {
        @include screen-768 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto;
            grid-gap: 5.111111vw;
        }

        @include screen-1600 {
            grid-gap: 80px;
        }
    }

    &__details {
        padding-bottom: 48px;

        @include screen-768 {
            align-self: start;
            grid-row: span 3;
            padding-bottom: 0;
        }

        &__heading {
            font-size: $fs-body*1.2;
            font-weight: $fw-regular;
            margin-bottom: 0.2em;
            margin-right: 1em;
            color: $color-grey-08;

            @include screen-560 {
                font-size: $fs-body*1.4;
            }
        }

        &__product-code {
            text-transform: uppercase;
            font-size: $fs-small;
        }

        &__selection {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $fs-body;

            &__title {
                font-size: $fs-body*1.2;
                margin-bottom: 0.2em;
                margin-right: 1em;
                color: $color-grey-08;

                @include screen-560 {
                    font-size: $fs-body*1.4;
                }
            }
        }
    }

    &__media {
        align-self: start;
        // this is a column
    }

    &__image {
        position: relative;

        &__flags {
            @include product-image-flags;
            @include product-flag;
        }

        &-slider {
            list-style-type: none;
            position: relative;
            width: 100%;

            .slider__dots {
                position: static;
                transform: none;
                justify-content: center;

                a {
                    display: block;
                    opacity: 0.5;
                    border: 1px solid transparent;
                    padding: 0;
                    border-radius: 2px;
                    transition:
                        opacity 250ms ease,
                        transform 250ms ease;

                    &:before {
                        content: none;
                    }

                    &.active {
                        opacity: 1;
                        background-color: $color-grey-01;
                        box-shadow: 0 1px 8px rgba(0,0,0,0.2);
                    }

                    &:hover,
                    &:focus {
                        opacity: 1;
                    }

                    img {
                        pointer-events: none;

                        @supports(mix-blend-mode: multiply) {
                            mix-blend-mode: multiply;
                        }
                    }
                }
            }

            .slider__arrows {
                z-index: 1;

                &.previous {
                    left: 6vw;
                    right: auto;

                    @include screen-768 {
                        left: auto;
                        right: 100%;
                    }
                }

                &.next {
                    right: 6vw;
                    left: auto;

                    @include screen-768 {
                        right: auto;
                        left: 100%;
                    }
                }
            }
        }

        &__picture {
            display: block;
        }

        &__slider {
            &__track {
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 100%;
                display: flex;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                scrollbar-width: none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    display: none;
                    width: 0;
                }
            }

            &__item {
                flex: 0 0 100%;
                scroll-snap-align: start;

                &__inner {
                    display: block;
                    position: relative;
                }
            }
        }
    }

    &__supplier-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -2px 1.5rem;
    }

    &__supplier-logo {
        display: block;
        flex: 0 0 auto;
        padding: 9px;
        max-height: 64px;
        border: 1px solid $color-grey-01;
        border-radius: 3px;
        margin: 2px;
    }

    &__purchase {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        margin-top: $fs-body;

        > * {
            flex: 0 0 auto;
        }

        &__status {
            margin-bottom: 0;
            margin-right: 1em;
            text-transform: uppercase;
            letter-spacing: 0.15em;

            @include screen-768 {
                margin-right: auto;
                margin-left: auto;
            }

            @media screen and (min-width: 50em) { /* 800px */
                margin-right: 1em;
            }

            &.sold-out {
                font-size: 200%;

                @media screen and (min-width: 50em) { /* 800px */
                    margin-right: 0;
                }
            }
        }

        &__back {
            text-align: right;
            margin-bottom: 0;
            flex: 1 1 100%;
            max-width: 100%;
        }

        &__unavailable {
            flex: 0 0 100%;
        }

        &.with-quantity {
            > * {
                flex: 1 1 auto;

                @include screen-992 {
                    flex: 0 0 auto;
                }
            }

            .product-page__purchase__status {
                flex-basis: 100%;
                margin-bottom: 0.5em;
                margin-right: 0;
                letter-spacing: 0.25em;
                color: $color-primary;

                @include screen-768 {
                    margin-right: 0;
                    margin-left: 0;
                }

                @include screen-992 {
                    flex-basis: auto;
                    margin-bottom: 0;
                    margin-right: 1em;
                    letter-spacing: 0.15em;
                }

                &.sold-out {
                    letter-spacing: 0.15em;
                    color: inherit;
                    margin-bottom: 0;

                    @include screen-560 {
                        flex: 0 0 auto;
                    }
                }
            }

            .product-page__purchase__back {
                text-align: left;

                @include screen-560 {
                    text-align: right;
                }
            }

            .product-page__purchase__quantity {
                margin-bottom: 0;
                flex-basis: 60px;

                @include screen-992 {
                    flex-basis: auto;
                }

                .field-input input {
                    padding-right: 0;
                    line-height: normal;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    @include screen-992 {
                        width: 60px;
                    }
                }

                + .button {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }

    .price {
        margin-bottom: 0;
        line-height: normal;

        &--current {
            margin-left: auto;

            .price__quantity {
                font-size: 200%;
                color: $color-00;
            }
        }

        &--original {
            .price__preceding {
                font-size: 75%;
            }
        }
    }

    &__price {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include product-flag;
        margin-top: $fs-body;

        p {
            margin-bottom: 0;
        }

        .flag + .price--original {
            margin-top: 0.25em;
            margin-bottom: calc((#{$fs-body} * 2) * 0.2);
        }

        &--sold-out {
            .product-page__purchase__status {
                line-height: normal;
            }

            .price--current {
                margin-left: 0;
            }
        }
    }

    .image-not-available {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($color-black, 0.85);
        color: $color-white;
        font-size: $fs-body;
        padding: 8px 40px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1.5px;

        @media screen and (min-width: 48em) { /* 768px */
            font-size: $fs-body*0.75;
        }

        @media screen and (min-width: 62em) { /* 992px */
            font-size: $fs-body;
        }

        span {
            display: block;
        }

        .not-available__variant {
            font-weight: $fw-bold;
        }
    }

    .product__options {
        .available-options {
            margin-bottom: 0.5em;
        }

        .size-options-list {
            li {
                font-size: $fs-body;
            }
        }
    }

    &__section {
        align-self: start;
    }

    &__introduction {
        border-bottom: 1px solid $color-grey-01;
        padding-bottom: $fs-body;
        margin-bottom: $fs-body;

        @include screen-768 {
            padding-bottom: $fs-body*1.5;
            margin-bottom: $fs-body*1.5;
        }

        p, ul, ol, blockquote {
            margin-bottom: 0.5em;
        }
    }

    &__options {
        padding: $fs-body 0;

        &.line-separators {
            margin: $fs-body 0;
            border-bottom: 1px solid $color-grey-01;
            border-top: 1px solid $color-grey-01;
        }

        &__title {
            color: $color-00;
        }

        &__intro {
            font-size: $fs-small;
        }

        &__personalise-name {
            .fieldset-input {
                margin-top: 2px;
            }
            .field-input input {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}


.product__size-chart {
    &.muliple-charts {
        @include box('small');
        @include box-lined($color-grey-06);
    }
}

.product-page__sizing {

    h4 {
        margin-bottom: 0;
        margin-top: 1em;
        font-size: $fs-small;
    }

    &:first-child h4 {
        margin-top: 0;
    }

    table {
        background-color: $color-grey-01;
        border-color: $color-white;

        th,
        td {
            border: 2px solid $color-white;
        }
    }
}
