.order-review {
    padding-top: 45px;

    @include screen-768 {
        padding-top: 63px;
    }

    @include screen-992 {
        padding-top: 0;
    }

    &__cart-total {
        align-items: flex-end;
        margin-top: 16px;
        margin-bottom: 24px;

        .total-price {
            font-size: 200%;
            line-height: 1;
        }
    }

    &__shipping {
        @include box('tiny');
        @include box-lined;

        &__status {
            margin-bottom: 0.5em;
            // font-family: $accent-font;
            // font-size: $fs-body*1.2;
            // font-weight: $fw-bold;
            // letter-spacing: 0.25em;
            // text-transform: uppercase;
        }
    }

    &__title {
        margin-bottom: $fs-body*0.5;
    }
}
