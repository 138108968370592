.form__checkout-email {
    @include screen-768 {
        input[type="submit"],
        button[type="submit"] {
            flex: 0 0 33.333%;
        }
    }
}

.form__password-reset {
    @include screen-768 {
        input[type="submit"],
        button[type="submit"] {
            flex: 0 0 33.333%;
        }
    }
}
