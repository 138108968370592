.order-summary {
    &__summary {
        .title-value-item__title {
            color: $color-00;
        }
    }

    &__note {
        font-family: $accent-font;
        letter-spacing: 0.15em;
        text-transform: uppercase;
    }
}
