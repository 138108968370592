

// Brand colours

$color-primary: 				#1D1E26; // Navy

$color-00:                      #1B244A; // Blue

$color-01:                      #571E25; // Burgundy

$color-02:                      #734226; // Walnut / tan

$color-03: 				        #BCAD98; // Beige / sand

$color-04:                      #364896;


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #F0F4F6;

$color-grey-02:						#E9EFF2;

$color-grey-03:						#b8b8b8;

$color-grey-04:						#9c9c9c;

$color-grey-05:						#7f7f7f;

$color-grey-06:						#6A6D73;

$color-grey-07:						#4A4B51;

$color-grey-08:						#2b2b2b;

$color-grey-09:						#0D0800;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#2D4A13;

$color-utility-positive-subtle:		#60962F;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;

$color-utility-selection:           #FFC57D;
