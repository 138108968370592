$line-spacing: $fs-body;

// Table styles
.order-table {
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    min-width: 0;
    vertical-align: top;
    line-height: 1.5;

    @media only screen and (max-width: $bp767) {
        display: block;
        border: none;

        tbody,
        tr {
            display: block;
            border: none;
        }
        thead,
        th {
            display: none;
        }
        tr {
            display: table;
            width: 100%;
        }
        tr.table--responsive__first-row {
            display: none;
        }
        td {
            display: table-row;
            padding: 0;
        }
        td[data-title]:before {
            content: attr(data-title);
            background-color: $color-grey-01;
            border-right: 1px solid $color-grey-02;
        }
        td[data-title] {
            border-left: 1px solid $color-grey-02;
            border-right: 1px solid $color-grey-02;
            border-top: 1px solid $color-grey-02;
        }
        tbody tr {
            margin-bottom: 0;
            border-bottom: none;
        }
        td[data-title]:before,
        .table--responsive__content {
            padding: 5px 15px;
            display: table-cell;
        }
    }

    th,
    thead td,
    td {
        padding: 0;
        border: 0;
        background: none;
    }

    th,
    thead td {
        @include screen-768 {
            position: relative;
            background-color: $color-grey-01;
            padding: 10px 10px calc(10px + 1em);

            &:before {
                content: "";
                position: absolute;
                height: 1em;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
            }
        }
    }

    .adjustments {
        h3 {
            font-size: $fs-small;
            font-family: $accent-font;
            text-transform: uppercase;
            margin-bottom: 0.2em;
            letter-spacing: 0.2em;
        }

        .order-table__adjustments__item {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 0.2em;

            &__label {
                text-align: left;
                font-size: $fs-small;
                flex: 1 1 auto;
                line-height: normal;
                margin-bottom: 0.2em;
                padding-right: $fs-body;
            }

            &__price {
                flex: 0 0 auto;
                margin-bottom: 0;
            }
        }

        &--order {
            padding-top: $fs-body;
        }

        &--order-totals {
            h3 {
                text-align: right;
                margin-right: -0.2em;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .row-divider-top {
        &:first-child {
            @include screen-768 {
                border-top: 1px solid $color-white;
            }
        }

        @include screen-768 {
            border-top: 1px solid $color-white;
            padding-top: $line-spacing;
        }
    }
}

.order-table {
    .field-element .field-label {
        color: $color-grey-04;
    }

    .field-element .title-value-item__title {
        color: $color-grey-06;
        margin-bottom: 0.25em;
    }

    &__header {
        &__title {
            display: table-cell;
            @include title-style-03;

            @include screen-560 {
                font-size: $fs-body*1.4;
            }
        }
    }

    &__body {
        vertical-align: top;
    }
}

.order-table__line-item {
    margin-bottom: 40px;

    @include screen-768 {
        background-color: $color-grey-01;
        border: none;
        padding: 0;
        margin-bottom: 0;
    }

    &__title {
        font-family: $accent-font;
        font-size: $fs-body*1.1;
        font-weight: $fw-regular;
        color: $color-00;
        margin-bottom: 0;
    }

    &__options {
        font-size: $fs-small;
    }

    .right-column {
        @media screen and (min-width: 36em) { /* 576px */
            border-left: none;
        }

        .sales-applied {
            @include product-flag;
        }
    }

    .line-total {
        border-top: 2px solid $color-white;
        padding-top: $line-spacing;
    }

    .field-element input,
    .field-element select {
        background-color: transparent;
        font-family: inherit;
        color: inherit;
        line-height: normal;
        height: 36px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .field-element.quantity input {
        width: 6em;
        padding-right: 0;
        font-weight: bold;
    }

    @media only screen and (max-width: 767px) {
        border-left: 2px solid $color-primary !important;

        td > * {
            padding-left: 10px !important;
        }
    }

    @include screen-768 {
        td {
            background-color: $color-grey-01;
            padding: 10px;
        }

        .field-element input,
        .field-element select,
        .checkbox__label--wrap .pseudo-input {
            background-color: $color-white;
        }

        &:nth-child(3n + 2) {
            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
            }
        }

        &:nth-child(3n) {
            td:last-child {
                border-bottom-right-radius: 12px;
            }
        }
    }

    .order-summary & {
        @include screen-768 {
            &:nth-child(3n + 2) {
                td:first-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                td:last-child {
                    border-top-right-radius: 0;
                }
            }

            &:nth-child(3n) {
                td:last-child {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    &--spacer {
        @include screen-768 {
            td {
                background-color: $color-white;
            }
        }

        @media only screen and (max-width: 767px) {
            height: 1.5em;
            margin-bottom: 1.5em !important;
            display: block !important;
            border-left: none !important;
            border-bottom: 2px solid $color-grey-03 !important;
        }
    }
}

.order-table__all-items {
    border-top: 1px solid $color-grey-01;
    border-bottom: 1px solid $color-grey-01;

    @include screen-768 {
        border-top: none;
    }

    @media only screen and (max-width: 767px) {
        border-top: 2px solid $color-grey-03 !important;
        margin-top: 1.5em;

        td {


            p {
                padding-top: 1em;
            }
        }
    }

    &.with-adjustments {
        margin-bottom: $line-spacing;
    }

    .item-sub-total {
        margin-bottom: 0;
        padding-bottom: $line-spacing;

        @include screen-768 {
            padding-top: $line-spacing;
        }
    }

    .quantity {
        font-size: 150%;
        line-height: 1.15;
    }

    .adjustments {
        border-bottom: 1px solid $color-grey-02;
        margin-bottom: $line-spacing;
    }
}


.order-table__order-overview {
    vertical-align: middle;

    @media only screen and (max-width: 767px) {
        border-top: 2px solid $color-grey-03 !important;
    }

    .coupon-code {
        margin-bottom: 0;

        @media only screen and (max-width: 767px) {
            padding-top: 1em;
        }

        &__title {
            @include title-style-03;
            margin-bottom: 0.25em;
        }
    }

    .adjustments {
        display: block;
        padding-top: $line-spacing;
        padding-bottom: $line-spacing;
        border-bottom: 1px solid $color-grey-01;

        @include screen-768 {
            display: table-cell;
        }
    }

    .error-message {
        font-size: $fs-body*1.25;
    }
}

.order-table__total {
    border-top: 1px solid $color-grey-01;
    padding-top: $line-spacing;
    padding-bottom: $line-spacing;

    @media only screen and (max-width: 767px) {
        display: block;
    }

    .total-price {
        padding-top: $line-spacing;
        padding-bottom: $line-spacing;

        @media only screen and (max-width: 767px) {
            display: block;
        }

        .quantity {
            font-size: 200%;
            line-height: 1;
        }
    }
}

.order-table {
    .right-column {
        text-align: right;
        vertical-align: bottom;

        p {
            margin-bottom: 0;
        }

        .line-total {
            margin-bottom: $line-spacing;

            @include screen-768 {
                margin-bottom: $line-spacing*2;
            }
        }
    }
}

.additional-info--mobile {
    display: block;

    @media screen and (min-width: 36em) { /* 576px */
        display: none;
    }
}

.additional-info--desktop {
    display: none;

    @media screen and (min-width: 36em) { /* 576px */
        display: block;
    }
}

.site-messages__item .order-table__line-item__title {
    color: inherit;
}

/* ---- Order Summary ---- */
.order-summary {
    .right-column + .order-table__line-item td {
        @include screen-768 {
            padding-top: $line-spacing;
        }
    }

    .order-table__line-item .line-total {
        border-top: none;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: $line-spacing;
        color: $color-grey-08;
    }

    .order-table__line-item .line-item__cost-summary {
        font-size: $fs-small;
    }

    .order-table__line-item .line-item__cost-summary .flag {
        padding: 0;
        background-color: transparent;
        color: inherit;
        letter-spacing: normal;
        text-transform: none;

        &.sale {
            color: $color-04;
        }
    }

    .order-table__order-overview .adjustments {
        font-size: $fs-small;
    }

    .additional-info--mobile {
        display: block;

        @media screen and (min-width: 62em) { /* 992px */
            display: none;
        }
    }

    .additional-info--desktop {
        display: none;

        @media screen and (min-width: 62em) { /* 992px */
            display: block;
        }
    }
}

.order-table__upload-image-message {
    display: inline-block;
    max-width: fit-content;
}
