
// Default Button
@mixin button() {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $color-white;
    background: $color-00;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    position: relative;
    font-family: $primary-font;
    font-weight: $fw-bold;
    line-height: normal;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    transition:
        background-color 250ms ease-in-out,
        color 250ms ease-in-out,
        border-color 250ms ease-in-out,
        box-shadow $link-transition;
    padding: 12px 27px;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($color-00, 10%);
        color: $color-white;
    }

    svg path {
        fill: $color-white;
    }

    &:after {
        content: "\00a0\003e";
    }
}

// Button Colours
@mixin button-secondary() {
    background-color: $color-00;
    border-color: $color-00;

    &:hover,
    &:focus {
        background-color: darken($color-00, 10%);
        border-color: darken($color-00, 10%);
    }
}

// Button Colours
@mixin button-alt() {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover,
    &:focus {
        background-color: darken($color-primary, 10%);
        border-color: darken($color-primary, 10%);
    }
}

/* Red Warning Button */
@mixin button-warning() {
    background-color: $color-utility-warning;
    color: $color-white;

    &:hover,
    &:focus {
        background-color: darken($color-utility-warning, 10%);
        color: $color-white;
    }
}

/* Light Grey */
@mixin button-neutral() {
    background-color: $color-grey-02;
    color: $color-grey-08;

    &:hover,
    &:focus {
        background-color: $color-grey-03;
        color: $color-grey-08;
    }

    svg path {
        fill: $color-grey-08;
    }
}

/* Mid Grey */
@mixin button-grey() {
    background-color: $color-grey-06;
    color: $color-white;

    &:hover,
    &:focus {
        background-color: $color-grey-07;
        color: $color-white;
    }

    svg path {
        fill: $color-white;
    }
}

/* Green */
@mixin button-positive() {
    background-color: $color-utility-positive-subtle;
    color: $color-white;

    &:hover,
    &:focus {
        background-color: $color-utility-positive;
        color: $color-white;
    }
}


@mixin text-button($text-colour: $color-00, $hover-colour: $color-primary) {
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $text-colour;
    background: transparent;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    position: relative;
    font-family: $primary-font;
    font-weight: $fw-bold;
    line-height: normal;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    transition:
        background-color 250ms ease-in-out,
        color 250ms ease-in-out,
        border-color 250ms ease-in-out;
    padding: 0;

    svg path {
        fill: $text-colour;
    }

    &:hover,
    &:focus,
    &:active {
        color: $hover-colour;

        svg path {
            fill: $hover-colour;
        }
    }

    &:after {
        content: "\00a0\003e";
    }
}

/* Width - Inline Padding  */
@mixin button-width-sm() {
    padding-left: 15px;
    padding-right: 15px;
}

@mixin button-width-reg() {
    padding-left: 27px;
    padding-right: 27px;
}

@mixin button-width-lg() {
    padding-left: 45px;
    padding-right: 45px;
}

/* Height - Block Padding */
@mixin button-height-sm() {
    padding-top: 9px;
    padding-bottom: 9px;
}

@mixin button-height-reg() {
    padding-top: 12px;
    padding-bottom: 12px;
}

@mixin button-height-lg() {
    padding-top: 18px;
    padding-bottom: 18px;
}

/* Text Size */
@mixin button-text-reg() {
    font-size: $fs-body;
}

@mixin button-text-lg() {
    font-size: $fs-body*1.4;
}
