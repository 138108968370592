.cart-notices {
    @include box('small');
    @include box-lined($color-grey-06, 'bottom');
    background-color: $color-grey-01;
    margin: 1em 0;

    .cart-notices__title {
        margin-bottom: 0;
    }

    .cart-notices__list {
        display: flex;
        flex-direction: column;
        padding-left: 1.5rem;
        margin-bottom: 0.5em;
        border-left: 3px solid $color-00;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        &.line-item-sale-price-changed {
            // not visible
        }

        &.invalid-coupon-removed {
            border-left-color: $color-utility-warning-subtle;
        }

        &.line-item-removed {
            border-left-color: $color-utility-warning-subtle;
        }

        &.shipping-method-changed {
            border-left-color: $color-utility-warning-subtle;
        }
    }

    .cart-notices__item {
        position: relative;
        display: block;
        padding: .75rem 0;

        &.warning {
            color: $color-utility-alert;
        }

        &.error {
            color: $color-utility-warning;
        }

        &.confirm {
            color: $color-utility-positive;
        }
    }
}
