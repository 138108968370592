
.product-tile__list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0 -10.5px;

    @include screen-560 {
        margin: 0 -13.5px;
    }

    @include screen-768 {
        margin: 0 -22.5px;
    }

    &--no-results {
        margin-left: auto;
        margin-right: auto;
    }
}

.product-tile__item {
    padding-left: 10.5px;
    padding-right: 10.5px;
    margin-bottom: 21px;
    flex: 0 0 100%;

    @include screen-360() {
        flex: 0 0 50%;
    }

    @include screen-560 {
        padding-left: 13.5px;
        padding-right: 13.5px;
        margin-bottom: 27px;
    }

    @include screen-768 {
        padding-left: 22.5px;
        padding-right: 22.5px;
        margin-bottom: 45px;
    }

    @include screen-1200 {
        flex: 0 0 25%;
    }
}


.product-tile__slider {
    .product-tile__list {
        flex-wrap: nowrap;
        padding-left: 6vw;
        padding-right: 6vw;
        padding-bottom: 36px;
        margin: 0;

        @include screen-768 {
            margin: 0;
            padding-left: 4vw;
            padding-right: 4vw;
        }

        @include screen-992 {

        }
    }

    .product-tile__item {
        scroll-snap-align: center;
        flex: 0 0 180px;

        @include screen-480 {
            flex: 0 0 232px;
        }

        @include screen-560 {
            flex: 0 0 280px;
        }

         @include screen-1200 {
            flex: 0 0 25%;
        }
    }

    .slider__arrows {
        top: 0;
        bottom: 0;
        height: auto;
        width: 12vw;
        z-index: 1;

        @include screen-768 {
            width: 8vw;
        }

        &.previous {
            right: auto;
            left: 0;
            background: transparent;
            background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.45) 50%, rgba(255,255,255,0.8) 100%);
        }

        &.next {
            left: auto;
            right: 0;
            background: transparent;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.45) 50%, rgba(255,255,255,0.8) 100%);
        }
    }

    &[data-item-count="4"] {
        @include screen-1200 {
            .slider__arrows {
                display: none;
            }

            .slider-dots {
                display: none;
            }
        }
    }
}

.product-tile__list.grid {
    @supports(display: grid) {
        margin: 0;
        display: grid;
        grid-gap: 21px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        @include screen-560 {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            grid-gap: 27px;
        }

        @include screen-768 {
            margin: 0;
            grid-gap: 45px;
        }

        @include screen-992 {
            grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
        }
    }

    .product-tile__item {
        @supports(display: grid) {
            flex: 0 0 auto;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;

            @include screen-768 {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
            }
        }
    }
}

.product-tile {
    position: relative;
    display: block;
    text-decoration: none;
    color: $color-primary;

    &__image {
        background-color: $color-grey-01;
        position: relative;
        margin-bottom: $fs-body;

        .image-overlay {
            position: absolute;
            left: -15px;
            bottom: 15px;

            @include screen-992 {
                left: -21px;
                bottom: 21px;
            }
        }

        &__flags {
           @include product-image-flags;
           @include product-flag;
        }
    }


    &__content {
        @include screen-480 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__heading {
        line-height: normal;
        font-size: $fs-body;
        margin-bottom: 0.5em;

        @include screen-480 {
            flex: 1 1 100%;
            max-width: 100%;
        }

        @include screen-768 {
            letter-spacing: 0.1em;
        }

        @include screen-1400 {
            letter-spacing: 0.25em;
        }

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                // text-decoration: underline;
            }
        }
    }

    &__price {
        color: $color-grey-06;
        margin: 0.5em 0;
        line-height: 1.1;
        display: flex;
        flex-wrap: wrap;

        @include screen-480 {
            flex: 0 0 auto;
            max-width: 100%;
        }

        @include screen-768 {
            margin: 0;
            line-height: 2;
        }

        &.image-overlay {
            margin-top: 0;
            display: none;

            @include screen-560 {
                display: block;
            }

            .quote {
                @include subtitleBlock($color-01);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);
            }
            .original-price {
                @include subtitleBlock($color-00);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);
            }

            .sale-price {
                @include subtitleBlock($color-00);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);

                &__pretitle {
                    font-size: 66.666%;
                    letter-spacing: normal;
                    top: 0;
                }
            }

            .sale-price + .original-price {
                display: inline-block;
                font-size: $fs-small * 0.8;
                background-color: $color-04;
                padding: 6px 12px;
                position: absolute;
                bottom: calc(100% - 6px);
                left: 9px;
                line-height: normal;

                @include screen-992 {
                    font-size: 75%;
                }
            }
        }

        .sale-price + .original-price {
            text-decoration: line-through;
            order: 0;
            font-size: $fs-small;
        }

        .sale-price {
            flex: 0 0 100%;
            order: 1;
        }

        .sale-price__pretitle {
            top: 0;
            font-size: $fs-small * 0.8;

            @include screen-992 {
                font-size: 75%;
            }
        }
    }

    .product__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .available-options {
            flex: 1 1 100%;
            max-width: 100%;
            margin-top: $fs-body*0.5;
            margin-bottom: 0;
            font-size: $fs-small;
            color: $color-grey-04;
        }
    }

    &__cta {
        @include text-button;
        line-height: 2;
    }

    .product-tile__image .product-tile__cta {
        display: none;

        @include screen-1200 {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 0.5em);
        }
    }
}

.hover-cta {
    &:hover {
        color: $color-primary;

        .product__picture {
            @include screen-1200 {
                opacity: 0.5;
            }
        }

        .product-tile__image .product-tile__cta {
            @include screen-1200 {
                display: block;
                animation: fadeInUp 300ms ease-in-out;
                animation-fill-mode: both;
            }
        }
    }

    &:focus {
        .product__picture {
            opacity: 0.5;
        }
    }

    @include screen-1200 {
        .product-tile__cta {
            display: none;
        }

        .product-tile__options {
            flex: 0 0 auto;
            order: 1;
        }
    }
}

.price-overlay {
    .product-tile__price {
        @include screen-560 {
            display: none;
        }
    }

    .product-tile__price.image-overlay {
        @include screen-560 {
            display: block;
        }
    }
}

.js .js-animate-section {
    .featured-products__header {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 300ms ease-in-out,
                transform 300ms ease-in-out;
        }
    }

    .product-tile__item:nth-child(-n+4) {
        @include screen-768 {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 300ms ease-in-out,
            transform 300ms ease-in-out;
        }
    }

    &.animate-section {
        .featured-products__header {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .product-tile__item:nth-child(-n+4) {
            @include screen-768 {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @for $i from 1 through 4 {
            .product-tile__item:nth-child(#{$i}) {
                transition-delay: $i * 250ms;
            }
        }
    }
}
