@import "01-components/text-blocks/_tooltip-mixins";

.floating-cart__anchor {
    position: relative;
    display: flex;
}

.floating-cart {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    @include tooltip-content-style;
    width: 280px;
    z-index: 60;
    text-align: left;

    &[aria-expanded="false"] {
        display: none;
    }

    @include tooltip-anchor('top', 'right');

    &__trigger {
        display: flex;
        align-items: flex-end;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;

        &__icon {
            display: block;
        }

        &__label {
            display: block;
            margin-left: 2px;

            span {
                display: block;
                background-color: $color-00;
                color: $color-white;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                padding-top: 1px;
                padding-bottom: 1px;
                font-weight: $fw-bold;
                text-align: center;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 2.8rem;
        line-height: 1;
    }

    &__exit {
        position: relative;
        height: 32px;

        button {
            height: 32px;
            width: 32px;
            display: block;
            cursor: pointer;
            background-color: transparent;
            background-image: url(../assets/icon-system/icon_form_cross.svg);
            background-size: contain;
            background-repeat: none;
            background-position: center center;
            background-repeat: no-repeat;
            text-decoration: none;
            border: none;
            outline: none;
            transition: all $link-transition;
            padding: 0;
        }
    }

    &__items-list {
        > *:first-child {
            margin-top: 0.5em;
        }

        > *:last-child {
            margin-bottom: 0.5em;
        }
    }

    &__item {
        margin-top: 1em;
        margin-bottom: 1em;
        display: block;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 1.33;

        &__description {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 0;
            font-family: $accent-font;
            margin-bottom: 0.25em;

            @include screen-768 {
                letter-spacing: 0.1em;
            }

            @include screen-1400 {
                letter-spacing: 0.25em;
            }

            a {
                text-decoration: none;
            }
        }

        &__notes {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 100%;
            margin-bottom: 0;

            @include product-flag(true);

            .quantity {
                &:after {
                    content: "\2002";
                }
            }
        }

        &__price {
            display: flex;
            align-items: baseline;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 100%;
            margin-bottom: 0;

            .price {
                margin-bottom: 0;

                &--original {
                    font-size: 75%;
                    margin-right: 0.5em;
                    text-decoration: line-through;
                }

                &--current {
                    font-weight: $fw-bold;
                }
            }

            .sale-unit-price {
                font-size: 75%;
            }
        }
    }

    &__adjustments {
        border-top: 1px solid $color-grey-02;
        border-bottom: 1px solid $color-grey-02;
        padding: 10px;

        &__title {
            font-family: $accent-font;
            color: $color-primary;
            margin-bottom: 0.25em;
            font-size: $fs-body;

            @include screen-768 {
                letter-spacing: 0.1em;
            }

            @include screen-1400 {
                letter-spacing: 0.25em;
            }
        }

        &__item {
            display: block;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            line-height: 1.33;
            margin-bottom: 0.5em;

            &__description {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                max-width: calc(100% - 40px);
                margin-bottom: 0;
                font-size: $fs-small;
                line-height: 1.5;
            }
        }
    }

    &__subtotal {
        border-top: 3px solid rgba($color-white, 0.15);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
        margin-top: 0;

        &__title {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 100%;
            font-family: $accent-font;

            @include screen-768 {
                letter-spacing: 0.1em;
            }

            @include screen-1400 {
                letter-spacing: 0.25em;
            }
        }

        &__quantity {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 100%;
            font-weight: $fw-bold;
        }
    }

    &__ctas {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1em;

        .button,
        .text-link {
            letter-spacing: 0.1em;
        }
    }

    &__empty-note {
        margin-bottom: 0;
    }
}
