.filter__field input[type="checkbox"],
.filter__field input[type="radio"] {
    @include vis-hidden;
}

.filter__field input[type="checkbox"] + label,
.filter__field input[type="radio"] + label {
    padding-left: 24px;
    position: relative;
    cursor: pointer;
    display: inline-block;
}

/* -- Field set pseudo elements -- */
.filter__field input[type="checkbox"] + label:before,
.filter__field input[type="radio"] + label:before {
    content: " ";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    position: relative;
    top: 1px;
    background-color: transparent;
    border: 1px solid $color-grey-03;
    margin-left: -24px;
}

/* Radio button (pseudo element) */
.filter__field input[type="radio"] + label:before {
    border-radius: $form-spacing*1.5;
}

/* -- Field set pseudo elements : checked -- */
.filter__field input[type="checkbox"]:checked + label {
    color: $color-primary;
}

.filter__field input[type="checkbox"]:checked + label:before,
.filter__field input[type="radio"]:checked + label:before {
    background-color: $color-00;
    border-color: $color-00;
}

.filter__field input[type="checkbox"]:checked + label:after,
.filter__field input[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    left: 0;
    top: 3px;
}

/* Checkbox box checked (pseudo element) */
.filter__field input[type="checkbox"]:checked + label:after {
   content: "\00a0";
   background-color: $color-00;
   background-image: url(../assets/icon-system/icon_form_tick-sm-white.svg);
   background-position: left 1px top;
   background-repeat: no-repeat;
   background-size: 90%;
   width: 15px;
   height: 15px;
}

/* Radio dot (pseudo element) */
.filter__field input[type="radio"]:checked + label:after {
    left: $form-spacing - 1;
    top: $form-spacing *1.5 - 1;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $color-grey-07;
}
