.filter__inner {
    position: relative;
    padding-bottom: 56px;
    padding-left: 20px;
    padding-right: 20px;

    @include screen-768 {
        padding-left: 0;
        padding-right: 0;
    }

    .filter__product-types {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: normal;

        li {
            display: block;
        }

        a {
            position: relative;
            display: inline-block;
            padding: 0;
            color: inherit;
            text-decoration: none;
            padding-top: 0.2666em;
            padding-bottom: 0.2666em;
            left: 0;
            transition:
                color $link-transition,
                background-color $link-transition,
                left $link-transition;

            &:before {
                content: "> ";
            }

            &:hover,
            &:focus {
                color: $color-grey-08;
                left: 8px;
            }
        }

        .product-type-current-item {
            font-weight: $fw-bold;
            color: $color-00;
        }
    }

    .filter__option__list {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        &[aria-expanded="false"] {
            @include vis-hidden;
        }

        &[aria-expanded="true"] {
            @include vis-hidden-reset;

            margin-top: 0.2666em;
        }

        &--depth2 {
            margin-left: 24px;
        }
    }

    .filter__section {
        margin-bottom: 0;
        padding-bottom: 20px;
        border-bottom: 2px solid $color-grey-01;

        &__header {
            margin-top: 20px;
        }

        &__toggle {
            -webkit-appearance: none;
            outline: none;
            border: none;
            background: transparent;
            display: flex;
            width: 100%;
            position: relative;
            cursor: pointer;
            text-align: left;
            font-family: $primary-font;

            padding: 0;

            &__label {
                text-transform: uppercase;
                color: $color-grey-08;
                font-size: $fs-body;

                line-height: normal;
                margin: 0;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
                max-width: 100%;
            }

            &__icon {
                flex: 0 0 40px;
                height: 1.2em;

                &:before,
                &:after {
                    content: "\00a0";
                    width: 12px;
                    height: 2px;
                    display: block;
                    position: absolute;
                    background-color: $color-grey-07;
                    margin: 0 auto;
                    right: 20px;
                    top: 50%;
                    transition:
                        color 400ms ease,
                        background-color 400ms ease,
                        transform 400ms ease,
                        top 400ms ease;
                }

                &:hover:before,
                &:focus:before,
                &:hover:after,
                &:focus:after {
                    background-color: $color-grey-09;
                }


                &:before {
                    transform: rotate(45deg) translateX(-5px);
                }

                &:after {
                    transform: rotate(-45deg) translateX(5px);
                }
            }

            &[aria-pressed="true"] {
                .filter__section__toggle__icon {
                    &:before,
                    &:after {
                        top: calc(50% - 6px);
                        background-color: $color-grey-08;
                    }

                    &:hover,
                    &:focus {
                        color: $color-grey-09;

                        &:before,
                        &:after,
                        &:before,
                        &:after {
                            background-color: $color-grey-09;
                        }
                    }
                }
            }

            &[aria-pressed="true"] .filter__section__toggle__icon:before {
                transform: rotate(-45deg) translateX(-5px);
            }

            &[aria-pressed="true"] .filter__section__toggle__icon:after {
                transform: rotate(45deg) translateX(5px);
            }
        }

        &__input {
            display: block;
            width: 100%;
            outline: none;
            padding-top: 17px;
            padding-bottom: 15px;
            padding-right: 12px;
            -webkit-appearance: none;
            line-height: 1.4;
            font-family: $primary-font;

            border: none;
            border-radius: 0;
            background-color: transparent;
            padding-left: 36px;
            background-image: url(../assets/icon-system/icon_search.svg);
            background-repeat: no-repeat, repeat-Y;
            background-position: center left;
            background-size: $form-spacing*3, $form-spacing*5;
        }

        &--product-types {
            padding-bottom: $fs-body;
            display: none;

            @include screen-768 {
                display: block;
            }
        }

        &--search {
            padding-bottom: 0;
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-grey-04;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $color-grey-04;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $color-grey-04;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $color-grey-04;
    }
}

.filter__parameters {
    padding: 15px 0;
}
.filter__parameters__title {
    display: inline-block;
    text-transform: uppercase;
    font-size: $fs-body*0.75;

    color: $color-grey-03;
    margin-bottom: 0;
    letter-spacing: 2px;

    @include screen-768 {
        display: block;
    }
}
.filter__parameter {
    position: relative;
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    background: $color-grey-01;
    color: $color-grey-07;
    text-decoration: none;
    border-radius: 0;
    border: none;
    outline: none;
    opacity: 1;
    font-family: $primary-font;
    line-height: 1.2;

    font-size: $fs-body;
    box-shadow: 0 2px 2px rgba(0,0,0,0);
    padding: 0 8px 0 3px;
    margin: 2px;
    transition: background-color $link-transition,
    color $link-transition,
    border-color $link-transition,
    box-shadow $link-transition;

    &--product-type {
        padding: 3px 8px;
    }
}
.filter__parameter-remove {
    display: inline-block;
    cursor: pointer;
    padding: 3px 5px 3px 5px;
}
.filter__parameter-remove:hover svg path {
    fill: #000000;
}

.button--load-more {
    margin: 20px 0;
}

.product-listing-row {
    margin-right: -27px;
    margin-left: -27px;

    @include screen-1600 {
        margin-right: -36px;
        margin-left: -36px;
    }

    > * {
        padding-right: 27px;
        padding-left: 27px;

        @include screen-1600 {
            padding-right: 36px;
            padding-left: 36px;
        }
    }

}

.product-listing-filters-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    width: 100%;

    &:before {
        content: none;
    }

    svg {
        margin-right: 8px;
        width: 1em;
        height: 1em;
    }

    @include screen-768 {
        display: none;
    }

    &[aria-pressed="false"] {
        .svg-open-filter {
            transform: translateY(0);
        }

        .svg-close-filter {
            transform: translateY(100%);
        }
    }

    &[aria-pressed="true"] {
        .svg-open-filter {
            transform: translateY(100%);
        }

        .svg-close-filter {
            transform: translateY(0);
        }
    }

    .svg-open-filter,
    .svg-close-filter  {
        transition: 250ms ease transform;
    }

    .product-filters-btn-label {
        transition: 250ms ease width;
    }
}

.product-listing-filters {
    &[aria-expanded="false"] {
        @media screen and (max-width: $bp767) {
            @include vis-hidden;
        }
    }

    &[aria-expanded="true"] {
        @media screen and (max-width: $bp767) {
            clip: auto;
            height: auto;
            overflow: visible;
            position: static;
            width: auto;
            animation: attentionGrab 800ms cubic-bezier(.76,.19,.68,.9) forwards;
        }
    }
}

/* Fade in */
@-webkit-keyframes attentionGrab {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.06);
	}
    50% {
		transform: scale(1);
	}
    75% {
		transform: scale(1.03);
	}
    100% {
        transform: scale(1);
    }
}
@keyframes attentionGrab {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.06);
	}
    50% {
		transform: scale(1);
	}
    75% {
		transform: scale(1.03);
	}
    100% {
        transform: scale(1);
    }
}

.product-listing-filters-trigger {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-03;
    border-color: $color-03;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2), 0 2px 24px rgba(0,0,0,0.2);

    &:before {
        content: none;
    }

    @include screen-768 {
        display: none;
    }
}

.search-results__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    grid-column: span 3;

    svg path {
        fill: $color-grey-07;
    }

    &__inner {
        text-align: center;
    }
}

.search-results__loading__title {
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 1px;
}

.search-results__results {
    background-color: $color-grey-01;
    padding: 27px;
    grid-column: span 3;
    width: 100%;
    text-align: center;
    color: $color-grey-08;

    &__heading {
        @include subtitleBlock($color-01);
        margin-bottom: 27px;
        display: inline-block;
    }
}
