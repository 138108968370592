// small
$bp360:     22.5em;
$bp480:     30em;

$bp559:     34.9375em;
$bp560:     35em;

$bp640:     40em;

// small
$bp767:     47.9375em;
$bp768:     48em;

// medium
$bp991:     61.9375em;
$bp992:     62em;

// large
$bp1199:     74.9375em;
$bp1200:     75em;
$bp1400:     87.5em;

// extra large
$bp1600:     100em;
$bp1800:     112.5em;
$bp1920:     120em;
$bp2400:     150em;


/*
Variable Example
*/
@media screen and (min-width: $bp360) {
}

/*
Breakpoint Mixins
*/
@mixin screen-360() {
    @media screen and (min-width: $bp360) {
        @content;
    }
}

@mixin screen-480() {
    @media screen and (min-width: $bp480) {
        @content;
    }
}

@mixin screen-560() {
    @media screen and (min-width: $bp560) {
        @content;
    }
}

@mixin screen-640() {
    @media screen and (min-width: $bp640) {
        @content;
    }
}

@mixin screen-768() {
    @media screen and (min-width: $bp768) {
        @content;
    }
}

@mixin screen-992() {
    @media screen and (min-width: $bp992) {
        @content;
    }
}

@mixin screen-1200() {
    @media screen and (min-width: $bp1200) {
        @content;
    }
}

@mixin screen-1400() {
    @media screen and (min-width: $bp1400) {
        @content;
    }
}

@mixin screen-1600() {
    @media screen and (min-width: $bp1600) {
        @content;
    }
}

@mixin screen-1800() {
    @media screen and (min-width: $bp1800) {
        @content;
    }
}

@mixin screen-1920() {
    @media screen and (min-width: $bp1920) {
        @content;
    }
}

@mixin screen-2400() {
    @media screen and (min-width: $bp2400) {
        @content;
    }
}
