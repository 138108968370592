/*
// Address Select
.address-list__title {
    @include tagline;
}

.address-list {
    @include box;
    @include box-lined;

    .button {
        @include button-00;
    }
}
*/

// Address Grid
.address-grid {
    @supports(display: grid) {
        display: grid;
        grid-gap: $spacing--medium;
        grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));

        &--large {
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        }
    }
}

// Address Options
.address-option {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;

    @supports(display: grid) {
        margin-bottom: 0;
    }

    &__label {
        border: 1px solid $color-grey-01;
        padding: 16px;

        @include screen-1400 {
            padding: 24px;
        }

        .label-body {
            line-height: 2;
            flex: 1 1 calc(100% - 24px);
            padding-left: 8px;
        }

        .address-data {
            margin-top: 8px;
        }
    }

    &__radio-btn {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        input[type=radio] {
            margin-top: 0.25em;
        }
    }
}

.address-option__label:focus-within {
    border-color: transparent;
    outline: 2px solid $color-00;
}


.order-review {
    .address-select {
        @include box('tiny');
        @include box-lined($color-grey-06);
        line-height: normal;
        margin-bottom: 0;
    }
}

.address-edit {
    margin-bottom: 0;
    margin-top: auto;
    flex: 0 0 100%;
}

.address-select__buttons {
    margin-bottom: 0;
    margin-top: auto;
}

.customer__addresses {
    .address-select {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }
}

.address-data {
    .emphasis {
        color: $color-primary;
    }

    .name,
    .phone,
    .address1,
    .address2,
    .business-name {
        display: block;
    }

    .city:after,
    .state-text:after {
        content: ",";
    }
}


.address-data + .address-data {
    margin-top: 8px;
}


.address-fieldset.ShippingAddress {
    margin-top: $fs-body*2;
}

.order-address {
    margin-bottom: 0;
}



.address-set-as {
    border-left: 3px solid $color-utility-positive-subtle;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 12px;
    margin-bottom: calc(#{$fs-body} + 0.25em);

    @include screen-768 {
        padding-left: 15px;
        margin-left: -16px;
    }

    &__title {
        font-size: $fs-small;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 0.25em;
    }

    &__item {
        font-size: $fs-small;
        margin-bottom: 0;

        i {
            margin-right: 4px;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


.gift-address {
    padding-bottom: 6px;

    .title-style-03 {
        color: $color-01;
    }

    .shippingAddress .box-lined {
        border-left-color: $color-01;
    }
}
