@mixin title-display() {
    color: $color-primary;
    font-size: $fs-body*2.4;

    @include screen-560 {
        font-size: $fs-body*4;
    }

    @include screen-992 {
        font-size: $fs-body*6;
    }
}

@mixin subtitle($small: false) {
    line-height: normal;
    text-transform: uppercase;
    font-weight: $fw-regular;
    font-family: $primary-font;
    letter-spacing: 0.25em;
    font-size: $fs-small;

    @include screen-992 {
        font-size: $fs-body;

        @if $small {
            font-size: $fs-small;
        }
    }
}

@mixin subtitleBlock($bg-colour: $color-03, $small: false) {
    display: block;
    color: $color-white;
    line-height: normal;
    text-transform: uppercase;
    font-weight: $fw-regular;
    font-family: $primary-font;
    letter-spacing: 0.25em;
    font-size: $fs-small;
    padding: 9px 15px;

    @if $bg-colour {
        background-color: $bg-colour;
    }

    @if $small {
        font-size: $fs-small;
        padding: 6px 9px;
    }

    @include screen-992 {
        font-size: $fs-body;
        padding: 12px 21px;

        @if $small {
            font-size: $fs-small;
            padding: 6px 9px;
        }
    }
}


@mixin title-style-01 {
    font-family: $accent-font;
    font-size: $fs-body*2.4;

    @include screen-560 {
        font-size: $fs-body*4;
    }

    @include screen-992 {
        font-size: $fs-body*6;
    }
}

@mixin title-style-02 {
    font-family: $accent-font;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-weight: normal;
    line-height: normal;
    font-size: $fs-body*1.2;

    @include screen-560 {
        font-size: $fs-body*1.8;
    }

    @include screen-992 {
        font-size: $fs-body*2.4;
    }
}

@mixin title-style-03 {
    font-family: $accent-font;
    font-size: $fs-body*1.2;
    font-weight: $fw-regular;
    color: $color-00;

    @include screen-992 {
        font-size: $fs-body*1.4;
    }
}


@mixin title-style-04 {
    font-family: $accent-font;
    font-weight: $fw-regular;
    font-size: $fs-body*1.2;

    @include screen-1200 {
        font-size: $fs-body*1.4;
    }
}

@mixin title-style-paragraph {
    font-family: $primary-font;
    font-size: $fs-body;
    font-weight: $fw-regular;
}
