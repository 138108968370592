@mixin form-textbox-styles {
    border: 1px solid $color-grey-02;
    background-color: $color-grey-01;
}

@mixin form-textbox {
    display: block;
    width: 100%;
    outline: none;
    line-height: normal;
	padding: 12px 16px;
    -webkit-appearance: none;
    line-height: 1.4;
}
