.product-card {
    position: relative;
    display: block;
    text-decoration: none;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $color-grey-01;
    margin-bottom: 27px;

    @include screen-560 {
        display: flex;
    }

    @include screen-768 {
        margin-bottom: 45px;
    }

    &__image {
        background-color: $color-grey-01;
        position: relative;
        margin-bottom: $fs-body;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include screen-560 {
            flex: 0 0 calc(50% - 54px);
            margin-bottom: 0;
        }

        @include screen-768 {
            flex: 0 0 calc(50% - 90px);
        }

        @include screen-1200 {
            flex: 0 0 400px;
        }

        .image-overlay {
            position: absolute;
            left: -15px;
            bottom: 15px;

            @include screen-992 {
                left: -21px;
                bottom: 21px;
            }
        }

        &__flags {
           @include product-image-flags;
           @include product-flag;
        }
    }

    &__picture {
        display: block;
        background-color: $color-grey-01;
        margin-bottom: 0;
        transition:
            opacity 300ms ease,
            mix-blend-mode 300ms ease;

        @supports(aspect-ratio: 3/4) {
            aspect-ratio: 3/4;
        }

        &__img {
            @supports(mix-blend-mode: multiply) {
                mix-blend-mode: multiply;
            }
        }

        .product-card__picture__img {
            @supports(object-fit: contain) {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 27px;

        @include screen-768 {
            padding: 45px;
        }

        p {
            @include screen-560 {
                margin-bottom: 0.5em;
            }
        }
    }

    &__heading {
        line-height: normal;
        margin-bottom: 0.5em;
        font-size: $fs-body*1.8;
        color: $color-primary;

        @include screen-560 {
            font-size: $fs-body*2.4;
        }

        @include screen-768 {
            // font-size: $fs-body*4;
            letter-spacing: 0.1em;
        }

        @include screen-1400 {
            letter-spacing: 0.25em;
        }
    }

    &__price {
        line-height: 1.1;
        margin-top: auto;
        color: $color-primary;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        @include screen-480 {
            flex: 0 0 auto;
            max-width: 100%;
        }

        @include screen-768 {
            letter-spacing: 0.1em;
        }

        @include screen-1400 {
            letter-spacing: 0.25em;
        }

        &__quantity {
            font-size: $fs-body*1.2;

            @include screen-560 {
                font-size: $fs-body*1.8;
            }
        }

        &.image-overlay {
            font-size: $fs-body;
            margin-top: 0;
            display: none;

            @include screen-560 {
                display: block;
            }

            .quote {
                @include subtitleBlock($color-01);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);
            }

            .original-price {
                @include subtitleBlock($color-00);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);
            }

            .sale-price {
                @include subtitleBlock($color-00);
                display: inline-block;
                box-shadow: 0 2px 8px rgba($color-black, 0.25);

                &__pretitle {
                    font-size: 66.666%;
                    letter-spacing: normal;
                    top: 0;
                }
            }

            .sale-price + .original-price {
                display: inline-block;
                font-size: $fs-small * 0.8;
                background-color: $color-04;
                padding: 6px 12px;
                position: absolute;
                bottom: calc(100% - 6px);
                left: 9px;
                line-height: normal;

                @include screen-992 {
                    font-size: 75%;
                }
            }
        }

        .sale-price + .original-price {
            text-decoration: line-through;
            order: 0;
        }

        .sale-price {
            flex: 0 0 100%;
            order: 1;
        }

        .sale-price__pretitle {
            top: 0;
            font-size: $fs-small * 0.8;

            @include screen-992 {
                font-size: 75%;
            }
        }
    }

    .product-card__content .product-card__price {
        margin-bottom: 0;
    }

    .product__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
        margin-top: $fs-body;

        .available-options {
            flex: 1 1 100%;
            max-width: 100%;
            font-size: $fs-body;
            margin-bottom: 0.5em;
            color: inherit;
        }
    }

    .colour-options-list {
        &.with-label {
            font-size: $fs-body;
        }
    }

    .size-options-list {
        li {
            font-size: $fs-body;
            color: inherit;
        }
    }

    &__cta {
        @include text-button;
        line-height: 2;
    }

    .product-card__image .product-card__cta {
        display: none;

        @include screen-1200 {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 0.5em);
        }
    }

    &__image-slider {
        display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: x mandatory;
        scrollbar-width: none;
        -ms-overflow-style: none;
	}
}

.hover-cta {
    @include screen-1200 {
        .product-card__cta {
            display: none;
        }

        .product-card__options {
            flex: 0 0 auto;
            order: 1;
        }
    }
}

.price-overlay {
    .product-card__price {
        @include screen-560 {
            display: none;
        }
    }

    .product-card__price.image-overlay {
        @include screen-560 {
            display: block;
        }
    }
}

.product-card__image-slider .product-card__picture {
	width: 100%;
	flex-shrink: 0;
	scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        object-fit: contain;
    }
}

.product-card__garment-template {
    margin-top: auto;
    margin-bottom: 1em;

    &__title {
        @include subtitle();
        display: block;
    }

    &__button-wrap {
        display: block;
        margin-top: 0.333em;
    }
}

.product-card__sizing {
    .text-link {
        text-align: left;
    }
}
