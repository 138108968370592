// typography
.checkout__subtitle {
    font-size: $fs-body;
    font-weight: $fw-bold;
    text-transform: uppercase;

    @media screen and (min-width: 87.5em) {/* 1400px */
        font-size: $fs-body*1.1;
    }
}

// visibility
.hidden {
    display: none;
}
.field-input .textbox.hidden {
    display: none;
}
.field-input .dropdown.hidden {
    display: none;
}
