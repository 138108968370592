.order__vouchers-listing {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;

    @include screen-560() {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }

    @include screen-768() {
        gap: 3em;
    }
}

.voucher-summary {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @include screen-640() {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
}
.voucher-summary__image {
    flex: 0 0 auto;

    @include screen-640() {
        flex: 0 0 33.3333%;
        padding-right: 1em;
    }
}

.voucher-summary__content {
    flex: 1 1 auto;

    @include screen-640() {
        flex: 0 0 66.6666%;
        padding-left: 1em;
    }

    &__title {
        color: $color-00;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        .title-value-item {
            flex: 1 1 auto;
            padding-right: 10px;

            &:last-of-type {
                margin-bottom: 1em;
            }
        }
    }

    &__cta {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        @include screen-640() {
            flex: 0 0 100%;
            grid-auto-flow: row;
            justify-content: initial;
        }

        .button {
            flex: 1 1 auto;
        }
    }
}

.cart__voucher-code {
    margin-bottom: 0;

    &__title {
        @include title-style-03;
        margin-bottom: 0.25em;
    }
}
