.payment-form .stripe-payment-intents-form,
.stripe-payment-intents-form {
    width: 100%;
}


input.text,
.card-data {
    width: 100%;
    display: block;
    width: 100%;
    outline: none;
    line-height: normal;
    padding: 10px 16px 10px;
    -webkit-appearance: none;
    line-height: 1.4;
    border: 2px solid $color-grey-02;
    border-radius: $standard-radius;
    background-color: $color-white;
    margin-bottom: $fs-body;
}



.payment-form {
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;

        > * {
            box-sizing: border-box;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 50%;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    input[type="text"],
    input[type="tel"] {
        margin-bottom: $fs-body;
        border: 2px solid $color-grey-02;
        border-radius: $standard-radius;
        background-color: $color-white;
        width: 100%;
    }

    legend {
        font-weight: $fw-bold;
    }

    .card-data {
        legend {
            background: white;
            padding: 2px 8px;
        }
    }
}

// Static payment form
#paymentForm input[type="text"],
#paymentForm input[type="tel"] {
    padding: 8px 16px;
    border: 1px solid $color-grey-02;
    border-radius: 0.25rem;
    line-height: 1.25;
    margin-bottom: 0.25rem;
}

#paymentForm fieldset {
    margin-bottom: 0.75rem;
}
