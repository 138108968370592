

.bulk-pricing {
    margin-top: $fs-body;
}

.bulk-pricing__colour-picker select {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 35px;
}

.bulk-pricing__title {
    @include subtitle;
    margin-bottom: 0.5em;
    color: $color-grey-08;
    font-size: $fs-body;

    &.no-bottom-margin {
        margin-bottom: 0;
    }
}

.bulk-pricing__tier-quantity {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .tier-quantity__item-total {
        flex: 0 0 auto;
        margin-bottom: 0;
        margin-right: 1em;
        margin-top: 30px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        transition: margin-top $link-transition;

        @include screen-768 {
            letter-spacing: normal;
        }

        @include screen-1200 {
            letter-spacing: 0.15em;
        }

        &__title {
            margin-bottom: 0;
            line-height: 1.5;
            padding: 0.2em 0 0.25em;
        }

        .label-long {
            display: none;

            @include screen-1200 {
                display: inline;
            }
        }
        .label-condensed {
            @include screen-1200 {
                display: none;
            }
        }
    }
}

.bulk-pricing__tiers {
    flex: 0 0 200px;
    max-width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    @include screen-992 {
        flex-basis: 250px;
    }


    > *:first-child {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    > *:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
}

.bulk-pricing__tier {
    padding: 0.2em 1em 0.25em;
    border: 1px solid $color-grey-01;
    margin-bottom: 0;
    margin-top: -1px;

    display: flex;
    justify-content: space-between;

    &.headings {
        text-transform: uppercase;
        font-size: $fs-small;
        letter-spacing: 0.25em;
        line-height: 23px;

        .bulk-pricing__quantity {
            font-weight: $fw-regular;

            .label-long {
                display: none;

                @include screen-1200 {
                    display: block;
                }
            }
            .label-condensed {
                @include screen-1200 {
                    display: none;
                }
            }
        }
    }
}

.bulk-pricing__quantity {
    display: block;
    font-weight: $fw-bold;
    text-align: right;
}

.bulk-pricing__price {
    display: block;
    text-align: left;
}

.bulk-pricing__variants-selector {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: $fs-body;

    @supports(display: grid) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
        margin-left: 0;
        margin-right: 0;
    }

    &.single {
        @supports(display: grid) {
            grid-template-columns: repeat(auto-fit, minmax(40px, 100%));
        }
    }
}

.bulk-pricing__variant {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    flex: 0 0 auto;
    max-width: 100%;

    @supports(display: grid) {
        padding-left: 0;
        padding-right: 0;
    }

    &-title {
        display: block;
    }

    &-quantity {
        display: block;
        @include form-textbox;
        @include form-textbox-styles;
        text-align: center;
        padding: 6px 0 6px 12px;
        border-radius: $standard-radius;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            margin-left: 6px;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    &-total {
        display: block;
        font-size: $fs-small;
    }
}

.bulk-pricing__total {
    text-transform: uppercase;
}

.bulk-pricing__minimum-quantity {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;

    &--highlight {
        font-weight: $fw-bold;
    }
}
