

/*
Basic system font stacks
*/

$primary-font: 'Poppins', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

/* Poppins Weights: Regular: 400, Bold: 700 */

$accent-font: 'Lato', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

/* Lato Weights: Light: 300, Regular: 400 */


/*
Weights
*/

$fw-light: 300;

$fw-regular: 400;

$fw-bold: 700;

/*
Sizes
*/
$fs-small: 1.2rem;

$fs-body: 1.5rem;
