@mixin box($size: 'regular') {
    padding: 15px;

    > *:last-child {
        margin-bottom: 0;
    }

    @if $size == 'small' {
        @include screen-360 {
            padding: 21px;
        }
    }

    @if $size == 'regular' {
        @include screen-360 {
            padding: 21px;
        }

        @include screen-768 {
            padding: 36px;
        }
    }

    @if $size == 'large' {
        @include screen-360 {
            padding: 21px;
        }

        @include screen-768 {
            padding: 45px;
        }
    }
}

@mixin box-lined($colour: $color-00, $accentBorder: 'left') {
    border: 1px solid $color-grey-01;
    border-radius: 3px;
    margin-bottom: $fs-body;

    @if $accentBorder == 'left' {
        border-left: 3px solid $colour;
    }

    @if $accentBorder == 'bottom' {
        border-bottom: 3px solid $colour;
    }

    @if $accentBorder == 'top' {
        border-top: 3px solid $colour;
    }
}
